var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { AppBar, Toolbar, IconButton, } from '@material-ui/core';
import cx from 'classnames';
import { SmallLogo } from '../icons';
import useStyles from './styles/NavigationBar.styles';
export var NavigationBar = function (_a) {
    var children = _a.children, className = _a.className, onIconClick = _a.onIconClick, rest = __rest(_a, ["children", "className", "onIconClick"]);
    var styles = useStyles();
    return (_jsx(AppBar, __assign({}, rest, { classes: {
            root: cx(styles.root, className),
        } }, { children: _jsxs(Toolbar, __assign({ classes: {
                root: styles.toolbar,
            } }, { children: [_jsx(IconButton, __assign({ onClick: onIconClick, disableRipple: true, className: styles.logoBlock }, { children: _jsx(SmallLogo, {}, void 0) }), void 0), children] }), void 0) }), void 0));
};
