import { Auth } from 'aws-amplify';
import React from 'react';

export interface UserData {
  attributes: {
    email: string,
    email_verified: boolean
    family_name: string,
    given_name: string,
    locale: string,
    sub: string,
  }
}

const useAuth = (refetchTimes?: number) => {
  const [data, setData] = React.useState<UserData | null>(null);
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState<null | Error>(null);

  React.useEffect(() => {
    const canContinue = async () => {
      try {
        const user = await Auth.currentAuthenticatedUser();
        setData(user);
      } catch (err) {
        setError((err as Error));
      } finally {
        setLoading(false);
      }
    };
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    canContinue();
  }, [refetchTimes]);

  return { loading, data, error };
};

export default useAuth;
