var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { makeStyles } from '@material-ui/core';
import cx from 'classnames';
import { DropdownMenu } from '../../DropdownMenu';
import { Body } from '../../Typography';
var useStyles = makeStyles(function (theme) { return ({
    menuItem: {
        display: 'flex',
        gap: 10,
    },
    blackLabel: {
        '& > *': {
            color: theme.palette.common.black + " !important",
            fontWeight: 500,
        },
        '&:hover *': {
            color: theme.palette.primary.main + " !important",
        },
    },
    errorLabel: {
        '& > *': {
            color: theme.palette.error.main + " !important",
            fontWeight: 500,
        },
        '&:hover': {
            backgroundColor: '#FFF6F5',
        },
    },
    errorLabelWithTopBorder: {
        borderTop: "1px solid " + theme.palette.grey[300],
        '& > *': {
            color: theme.palette.error.main + " !important",
            fontWeight: 500,
        },
        '&:hover': {
            backgroundColor: '#FFF6F5',
        },
    },
}); });
export var DrawerMenuItems = function (_a) {
    var anchorEl = _a.anchorEl, handleClose = _a.handleClose, menuItems = _a.menuItems;
    var classes = useStyles();
    return (_jsx(DropdownMenu, __assign({ "aria-label": 'entry-menu-items', anchorEl: anchorEl, open: Boolean(anchorEl), handleClose: handleClose }, { children: menuItems === null || menuItems === void 0 ? void 0 : menuItems.map(function (_a) {
            var key = _a.key, label = _a.label, icon = _a.icon, style = _a.style, onClick = _a.onClick;
            return (_jsxs(DropdownMenu.Item, __assign({ className: cx(classes.menuItem, style && classes[style]), onClick: onClick }, { children: [icon || _jsx(_Fragment, {}, void 0), _jsx(Body, __assign({ variant: 'large' }, { children: label }), void 0)] }), key));
        }) }), void 0));
};
