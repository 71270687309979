var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
/* eslint-disable @typescript-eslint/no-shadow */
import { useCallback, useState, } from 'react';
import { makeStyles } from '@material-ui/core';
import Cropper from 'react-easy-crop';
import { Dialog } from '../Dialog';
import { getCroppedImg, urltoFile } from './canvasUtils';
import { theme } from '../../utils';
import { Tooltip } from '../Tooltip';
var useStyles = makeStyles(function () { return ({
    uploadImage: {
        display: 'block',
        cursor: 'pointer',
        '& > input[type="file"]': {
            display: 'none',
        },
    },
    cropperContainer: {
        position: 'relative !important',
        height: 500,
        '& > img': {
            position: 'relative !important',
        },
    },
    overlay: {
        position: 'relative',
        '& > :first-child': {
            transition: theme.transitions.create('all'),
            borderRadius: function (_a) {
                var cropShape = _a.cropShape;
                return (cropShape === 'round' ? '50%' : '100%');
            },
            cursor: 'pointer',
            border: '1px solid transparent',
            '&:hover': {
                border: function (_a) {
                    var borderColorOnHover = _a.borderColorOnHover;
                    return "1px solid " + borderColorOnHover;
                },
            },
        },
        '&:hover $removeFile': {
            opacity: 1,
        },
    },
    tooltip: {
        display: 'inline-block',
    },
    removeFile: {
        position: 'absolute',
        transition: theme.transitions.create('all'),
        borderRadius: function (_a) {
            var cropShape = _a.cropShape;
            return (cropShape === 'round' ? '50%' : '100%');
        },
        right: 0,
        zIndex: 1,
        opacity: 0,
        border: '1px solid transparent',
        '&:hover': {
            border: function (_a) {
                var borderColorOnHover = _a.borderColorOnHover;
                return "1px solid " + borderColorOnHover;
            },
        },
        cursor: 'pointer',
    },
}); });
export var ImageUpload = function (_a) {
    var id = _a.id, _b = _a.locale, locale = _b === void 0 ? {
        confirmButton: 'Confirm',
        declineButton: 'Cancel',
        title: 'Crop image',
    } : _b, _c = _a.tooltip, tooltip = _c === void 0 ? 'Upload image' : _c, _d = _a.crop, crop = _d === void 0 ? { x: 0, y: 0 } : _d, _e = _a.zoom, zoom = _e === void 0 ? 1 : _e, _f = _a.aspect, aspect = _f === void 0 ? 4 / 3 : _f, _g = _a.cropShape, cropShape = _g === void 0 ? 'round' : _g, component = _a.component, _h = _a.borderColorOnHover, borderColorOnHover = _h === void 0 ? theme.palette.grey[500] : _h, _j = _a.disableHover, disableHover = _j === void 0 ? false : _j, getImageAsFile = _a.getImageAsFile, getCroppedImage = _a.getCroppedImage, _k = _a.hasDeleteBtn, hasDeleteBtn = _k === void 0 ? false : _k, fileName = _a.fileName, isDisabled = _a.isDisabled, _l = _a.hasTooltip, hasTooltip = _l === void 0 ? true : _l;
    var classes = useStyles({
        borderColorOnHover: disableHover ? 'transparent' : borderColorOnHover,
        cropShape: cropShape,
    });
    var _m = __read(useState(undefined), 2), imageSrc = _m[0], setImageSrc = _m[1];
    var _o = __read(useState(false), 2), isModalOpen = _o[0], setIsModalOpen = _o[1];
    var _p = __read(useState(crop), 2), cropState = _p[0], setCropState = _p[1];
    var _q = __read(useState(null), 2), croppedAreaPixels = _q[0], setCroppedAreaPixels = _q[1];
    var _r = __read(useState(zoom), 2), zoomState = _r[0], setZoomState = _r[1];
    var _s = __read(useState(undefined), 2), croppedImage = _s[0], setCroppedImage = _s[1];
    var _t = __read(useState(Math.random()), 2), inputKey = _t[0], setInputKey = _t[1];
    var _u = __read(useState(null), 2), imageAsFile = _u[0], setImageAsFile = _u[1];
    var onCropComplete = useCallback(function (_, croppedAreaPixels) {
        setCroppedAreaPixels(croppedAreaPixels);
    }, []);
    var readFile = function (file) { return new Promise(function (resolve) {
        var reader = new FileReader();
        reader.addEventListener('load', function () { return resolve(reader.result); }, false);
        reader.readAsDataURL(file);
    }); };
    var showCroppedImage = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        var croppedImage_1, file, e_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 4, , 5]);
                    if (!(imageSrc && croppedAreaPixels)) return [3 /*break*/, 3];
                    return [4 /*yield*/, getCroppedImg(imageSrc, croppedAreaPixels)];
                case 1:
                    croppedImage_1 = _a.sent();
                    setCroppedImage(croppedImage_1);
                    if (getCroppedImage) {
                        getCroppedImage(croppedImage_1);
                    }
                    return [4 /*yield*/, urltoFile(croppedImage_1, fileName || 'croppedImage', 'image/jpeg')];
                case 2:
                    file = _a.sent();
                    if (file) {
                        getImageAsFile(file);
                    }
                    setImageAsFile(file);
                    setIsModalOpen(false);
                    setImageSrc(undefined);
                    setInputKey(Math.random());
                    _a.label = 3;
                case 3: return [3 /*break*/, 5];
                case 4:
                    e_1 = _a.sent();
                    console.error(e_1);
                    return [3 /*break*/, 5];
                case 5: return [2 /*return*/];
            }
        });
    }); }, [imageSrc, croppedAreaPixels]);
    var onFileChange = function (e) { return __awaiter(void 0, void 0, void 0, function () {
        var file, imageDataUrl;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!(e.target.files && e.target.files.length > 0)) return [3 /*break*/, 2];
                    file = e.target.files[0];
                    return [4 /*yield*/, readFile(file)];
                case 1:
                    imageDataUrl = _a.sent();
                    setImageSrc(imageDataUrl);
                    setInputKey(Math.random());
                    setIsModalOpen(true);
                    setImageAsFile(null);
                    _a.label = 2;
                case 2: return [2 /*return*/];
            }
        });
    }); };
    var onClose = useCallback(function () {
        setImageSrc(undefined);
        setCroppedImage(undefined);
        setImageAsFile(null);
        setIsModalOpen(false);
        setInputKey(Math.random());
    }, []);
    var handleDeleteFile = function (e) {
        e.preventDefault();
        onClose();
        getImageAsFile(null);
        getCroppedImage && getCroppedImage(undefined);
    };
    return (_jsxs(_Fragment, { children: [_jsx(Dialog, __assign({ locale: locale, open: isModalOpen, handleClose: onClose, handleConfirm: showCroppedImage }, { children: _jsx("div", { children: _jsx(Cropper, { image: imageSrc, crop: cropState, zoom: zoomState, aspect: aspect, cropShape: cropShape, onCropChange: setCropState, onCropComplete: onCropComplete, classes: {
                            containerClassName: classes.cropperContainer,
                        }, onZoomChange: setZoomState }, void 0) }, void 0) }), void 0), _jsxs("label", __assign({ className: classes.uploadImage, htmlFor: id || 'image-upload' }, { children: [_jsx("input", { type: 'file', onClick: function () {
                            setCroppedImage(undefined);
                            setImageAsFile(null);
                        }, onChange: onFileChange, accept: 'image/*', disabled: isDisabled, id: id || 'image-upload' }, inputKey), hasTooltip ? (_jsx(Tooltip, __assign({ title: tooltip, className: classes.tooltip }, { children: _jsxs("div", __assign({ className: classes.overlay }, { children: [component({ croppedImage: croppedImage, originalImage: imageSrc, imageAsFile: imageAsFile }), hasDeleteBtn && (_jsx("button", __assign({ disabled: isDisabled, onClick: handleDeleteFile, className: classes.removeFile }, { children: "x" }), void 0))] }), void 0) }), void 0))
                        : component({
                            croppedImage: croppedImage,
                            originalImage: imageSrc,
                            imageAsFile: imageAsFile,
                            handleDeleteFile: handleDeleteFile,
                        })] }), void 0)] }, void 0));
};
