var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable react/no-array-index-key */
import { useEffect, useState } from 'react';
import { Tabs as MuiTabs, Tab as MuiTab, makeStyles, } from '@material-ui/core';
import cx from 'classnames';
var useStyles = makeStyles(function (theme) { return ({
    tabsStyle: {
        position: 'relative',
        '&::after': {
            position: 'absolute',
            display: 'block',
            content: '""',
            bottom: 0,
            left: 0,
            height: 2,
            width: '100%',
            backgroundColor: theme.palette.grey[300],
        },
        '& > button': {
            color: theme.palette.common.black,
            marginRight: 32,
        },
    },
    tabStyle: {
        fontWeight: 500,
        fontSize: 14,
        textTransform: 'none',
        minWidth: 'initial',
        padding: 0,
        opacity: 1,
    },
    tabSelected: {
        color: theme.palette.primary.main + " !important",
        fontWeight: 700,
    },
    tabIndicator: {
        background: theme.palette.primary.main,
        borderRadius: '8px 8px 0 0',
    },
    divider: {
        position: 'absolute',
        backgroundColor: theme.palette.grey[300],
        width: '100%',
        height: 2,
        bottom: 0,
    },
    tabInRight: {
        marginLeft: 'auto',
    },
    lastTabInRight: {
        marginRight: '0 !important',
    },
}); });
var TabPanel = function (_a) {
    var children = _a.children, value = _a.value, index = _a.index;
    if (value === index) {
        return _jsx(_Fragment, { children: children }, void 0);
    }
    return _jsx(_Fragment, {}, void 0);
};
export var Tabs = function (_a) {
    var _b = _a.tabTitles, tabTitles = _b === void 0 ? ['Item 1', 'Item 2'] : _b, _c = _a.contents, contents = _c === void 0 ? [_jsx(_Fragment, { children: "Content 1" }, void 0), _jsx(_Fragment, { children: "Content 2" }, void 0)] : _c, _d = _a.selectedTabIndex, selectedTabIndex = _d === void 0 ? 0 : _d, _e = _a.disabledTabs, disabledTabs = _e === void 0 ? [] : _e, _f = _a.tabToStartPositionRight, tabToStartPositionRight = _f === void 0 ? '' : _f, props = __rest(_a, ["tabTitles", "contents", "selectedTabIndex", "disabledTabs", "tabToStartPositionRight"]);
    var styles = useStyles();
    var _g = __read(useState(selectedTabIndex), 2), value = _g[0], setValue = _g[1];
    useEffect(function () {
        setValue(selectedTabIndex);
    }, [selectedTabIndex]);
    return (_jsxs(_Fragment, { children: [_jsx(MuiTabs, __assign({}, props, { value: value, onChange: function (_, val) {
                    setValue(val);
                }, classes: {
                    indicator: styles.tabIndicator,
                    flexContainer: styles.tabsStyle,
                } }, { children: tabTitles.map(function (label, i) {
                    var _a;
                    return (_jsx(MuiTab, { disabled: disabledTabs.includes(label.toString()), classes: {
                            root: styles.tabStyle,
                            selected: styles.tabSelected,
                        }, disableRipple: true, label: label, className: cx((_a = {},
                            _a[styles.tabInRight] = tabToStartPositionRight === label,
                            _a[styles.lastTabInRight] = !!tabToStartPositionRight && i === tabTitles.length - 1,
                            _a)) }, i));
                }) }), void 0), contents.map(function (content, i) { return (_jsx(TabPanel, __assign({ value: value, index: i }, { children: content }), i)); })] }, void 0));
};
