var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable no-param-reassign */
import { ClickAwayListener } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import EmojiPicker from 'emoji-picker-react';
import cx from 'classnames';
import Linkify from 'linkify-react';
import { useEffect, useRef, useState, } from 'react';
import { Avatar, Icons } from '../..';
import { Button } from '../Button';
import { Caption } from '../Typography';
var useStyles = makeStyles(function (theme) { return ({
    root: {
        display: 'flex',
        alignItems: 'center',
        gap: 8,
        '&:hover #replyBtn': {
            display: 'flex',
        },
    },
    senderRoot: {
        flexDirection: 'row-reverse',
        '&:hover #editBtn': {
            display: 'flex',
        },
        '&:hover #deleteBtn': {
            display: 'flex',
        },
    },
    container: {
        padding: '8px 16px',
        maxWidth: 440,
        wordBreak: 'break-word',
        whiteSpace: 'pre-wrap',
    },
    avatar: {
        display: 'flex',
        alignSelf: 'flex-end',
    },
    inputContainer: {
        padding: '8px',
        width: '100%',
        maxWidth: 440,
        wordBreak: 'break-word',
        display: 'flex',
        alignItems: 'center',
        position: 'relative',
    },
    sender: {
        backgroundColor: theme.palette.primary.main,
        borderRadius: '16px 16px 0px 16px',
        color: theme.palette.common.white,
        fontFamily: theme.typography.fontFamily,
        fontWeight: 500,
        fontSize: '14px',
        lineHeight: '24px',
        '& > a': {
            color: theme.palette.common.white,
        },
    },
    others: {
        backgroundColor: theme.palette.grey[100],
        borderRadius: '16px 16px 16px 0px',
        color: theme.palette.common.black,
        fontFamily: theme.typography.fontFamily,
        fontWeight: 500,
        fontSize: '14px',
        lineHeight: '24px',
        '& > a': {
            color: theme.palette.primary.main,
        },
    },
    group: {
        display: 'flex',
        flexDirection: 'column',
        gap: 8,
        backgroundColor: theme.palette.grey[100],
        borderRadius: '16px 16px 16px 0px',
        color: theme.palette.common.black,
        fontFamily: theme.typography.fontFamily,
        fontWeight: 500,
        fontSize: '14px',
        lineHeight: '24px',
        '& > a': {
            color: theme.palette.primary.main,
        },
    },
    time: {
        color: theme.palette.grey[500],
    },
    icon: {
        color: theme.palette.grey[500],
        '&#deleteBtn': {
            display: 'none',
        },
        '&#editBtn': {
            display: 'none',
        },
        '&#replyBtn': {
            display: 'none',
        },
    },
    input: {
        resize: 'none',
        overflow: 'hidden',
        backgroundColor: '#DAECFF',
        border: '2px solid #7CA6F4E5',
        padding: '8px 36px 8px 8px',
        borderRadius: 12,
        '&:focus': {
            outline: 'none',
        },
        fontFamily: theme.typography.fontFamily,
        height: 'auto',
        width: '100%',
        color: theme.palette.common.black,
    },
    emojiContainer: {
        backgroundColor: 'transparent',
        borderRadius: 0,
        paddingLeft: 0,
        paddingRight: 0,
    },
    emoji: {
        fontSize: 40,
        lineHeight: '48px',
    },
    gif: {
        backgroundColor: 'transparent',
        borderRadius: 0,
        paddingLeft: 0,
        paddingRight: 0,
    },
    reactionsBtn: {
        padding: 6,
        position: 'absolute',
        top: 12,
        right: 10,
        color: theme.palette.grey[500],
        transition: '0.2s',
        cursor: 'pointer',
        '&:hover': {
            color: theme.palette.primary.main,
        },
    },
    emojiPickerContainer: {
        position: 'absolute',
        right: 0,
        top: -460,
        zIndex: 10,
    },
}); });
var Bubble = function (_a) {
    var _b, _c, _d;
    var children = _a.children, style = _a.style, className = _a.className, type = _a.type, time = _a.time, isEditMode = _a.isEditMode, setEditMode = _a.setEditMode, showEdit = _a.showEdit, showDelete = _a.showDelete, onDelete = _a.onDelete, onEdit = _a.onEdit, onReply = _a.onReply, userAvatar = _a.userAvatar, userName = _a.userName, isEdited = _a.isEdited, hasOnlyEmoji = _a.hasOnlyEmoji, hideBackground = _a.hideBackground, _e = _a.locales, locales = _e === void 0 ? {
        edited: 'edited',
    } : _e, rest = __rest(_a, ["children", "style", "className", "type", "time", "isEditMode", "setEditMode", "showEdit", "showDelete", "onDelete", "onEdit", "onReply", "userAvatar", "userName", "isEdited", "hasOnlyEmoji", "hideBackground", "locales"]);
    var classes = useStyles();
    var textRef = useRef(null);
    var _f = __read(useState(''), 2), inputValue = _f[0], setInputValue = _f[1];
    var _g = __read(useState(false), 2), isEmojiOpen = _g[0], setIsEmojiOpen = _g[1];
    useEffect(function () {
        if (textRef.current) {
            textRef.current.style.height = 'auto';
            var scrollHeight = textRef.current.scrollHeight;
            textRef.current.style.height = scrollHeight + 4 + "px";
        }
    }, [inputValue]);
    var linkifyOptions = {
        target: '_blank',
        rel: 'noopener noreferrer',
    };
    var onEmojiClick = function (emojiData) {
        setInputValue(function (prev) { return prev + emojiData.emoji; });
    };
    return (_jsxs("div", __assign({ className: cx(className, classes.root, (_b = {},
            _b[classes.senderRoot] = type === 'sender',
            _b)), style: style }, rest, { children: [type === 'group' && _jsx(Avatar, { containerClassName: classes.avatar, size: 's', src: userAvatar, placeHolder: userName, alt: userName }, void 0), !isEditMode ? (_jsxs("div", __assign({ className: cx(classes.container, (_c = {},
                    _c[classes.sender] = type === 'sender',
                    _c[classes.others] = type === 'others',
                    _c[classes.group] = type === 'group',
                    _c[classes.emojiContainer] = hasOnlyEmoji,
                    _c[classes.gif] = hideBackground,
                    _c)) }, { children: [type === 'group' && _jsx(Caption, __assign({ weight: 700 }, { children: userName }), void 0), typeof children === 'string' ? (_jsx(Linkify, __assign({ options: linkifyOptions, className: cx((_d = {},
                            _d[classes.emoji] = hasOnlyEmoji,
                            _d)) }, { children: children }), void 0)) : children] }), void 0)) : (_jsxs("div", __assign({ className: cx(classes.inputContainer, classes.sender) }, { children: [_jsx("textarea", { ref: textRef, rows: 1, className: classes.input, value: inputValue, onChange: function (e) { return setInputValue(e.target.value); } }, void 0), _jsx(Icons.Reactions, { className: classes.reactionsBtn, onClick: function () { return setIsEmojiOpen(!isEmojiOpen); } }, void 0), isEmojiOpen && (_jsx(ClickAwayListener, __assign({ onClickAway: function () { return setIsEmojiOpen(false); } }, { children: _jsx("div", __assign({ className: classes.emojiPickerContainer }, { children: _jsx(EmojiPicker, { previewConfig: {
                                    showPreview: false,
                                }, onEmojiClick: onEmojiClick }, void 0) }), void 0) }), void 0))] }), void 0)), !isEditMode && showDelete && (_jsx(Button, { id: 'deleteBtn', variant: 'fixedIcon', color: 'ghost', size: 'small', icon: _jsx(Icons.Trash, {}, void 0), className: classes.icon, onClick: onDelete }, void 0)), !isEditMode && showEdit && typeof children === 'string' && (_jsx(Button, { id: 'editBtn', variant: 'fixedIcon', color: 'ghost', size: 'small', icon: _jsx(Icons.EditAlt, {}, void 0), className: classes.icon, onClick: function () {
                    setEditMode && setEditMode(true);
                    setInputValue(children || '');
                } }, void 0)), !isEditMode && typeof children === 'string' && (_jsx(Button, { id: 'replyBtn', variant: 'fixedIcon', color: 'ghost', size: 'small', icon: _jsx(Icons.Reply, {}, void 0), className: classes.icon, onClick: onReply }, void 0)), isEditMode && (_jsx(Button, { variant: 'fixedIcon', icon: _jsx(Icons.Check, {}, void 0), size: 'small', disabled: !inputValue, onClick: function () { return onEdit && onEdit(inputValue); } }, void 0)), isEditMode && (_jsx(Button, { variant: 'fixedIcon', color: 'ghost', icon: _jsx(Icons.Close, {}, void 0), size: 'small', className: classes.icon, onClick: function () {
                    setEditMode && setEditMode(false);
                    setInputValue('');
                } }, void 0)), _jsxs(Caption, __assign({ variant: 'bold', weight: 500, className: classes.time }, { children: [time, isEdited && " (" + (locales.edited || '') + ")"] }), void 0)] }), void 0));
};
export default Bubble;
