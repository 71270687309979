var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import useStyles from './styles';
var EditFilled = function (props) { return (_jsx("span", __assign({ className: useStyles().jobDoneIcon }, props, { children: _jsxs("svg", __assign({ width: '24', height: '24', viewBox: '0 0 24 24', fill: 'none', xmlns: 'http://www.w3.org/2000/svg' }, { children: [_jsx("rect", { x: '2', y: '2', width: '20', height: '20', rx: '4', fill: '#DADDE4' }, void 0), _jsx("path", { d: 'M5.87495 19H9.58468C9.69982 19.0007 9.81397 18.9786 9.92057 18.9351C10.0272 18.8915 10.1241 18.8274 10.2059 18.7463L16.2604 12.6848L18.7453 10.2532C18.8273 10.1719 18.8924 10.0751 18.9368 9.96852C18.9812 9.86193 19.0041 9.74761 19.0041 9.63214C19.0041 9.51667 18.9812 9.40235 18.9368 9.29576C18.8924 9.18917 18.8273 9.09243 18.7453 9.01112L15.0355 5.25873C14.9542 5.17675 14.8574 5.11168 14.7508 5.06727C14.6442 5.02286 14.5298 5 14.4143 5C14.2988 5 14.1845 5.02286 14.0778 5.06727C13.9712 5.11168 13.8745 5.17675 13.7931 5.25873L11.3258 7.73408L5.25375 13.7956C5.17266 13.8774 5.1085 13.9743 5.06496 14.0809C5.02142 14.1874 4.99935 14.3015 5.00001 14.4167V18.1253C5.00001 18.3573 5.0922 18.5798 5.25628 18.7438C5.42036 18.9078 5.6429 19 5.87495 19ZM14.4143 7.11306L16.8904 9.58841L15.648 10.8305L13.1719 8.3551L14.4143 7.11306ZM6.74989 14.7753L11.9383 9.58841L14.4143 12.0638L9.22595 17.2506H6.74989V14.7753Z', fill: '#7F8897' }, void 0)] }), void 0) }), void 0)); };
export default EditFilled;
