var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { makeStyles } from '@material-ui/core';
import { Avatar } from '../../Avatar';
import { Body, Caption } from '../../Typography';
var useStyles = makeStyles(function (theme) { return ({
    root: {
        display: 'flex',
        alignItems: 'center',
    },
    nameContainer: {
        verticalAlign: 'super',
        marginLeft: 8,
        display: 'flex',
        flexDirection: 'column',
    },
    customId: {
        color: theme.palette.grey[700],
        maxWidth: 192,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
}); });
export var AvatarNameCell = function (_a) {
    var _b = _a.name, name = _b === void 0 ? 'Name Surname' : _b, src = _a.src, customId = _a.customId, _c = _a.placeHolder, placeHolder = _c === void 0 ? 'Name Surname' : _c;
    var classes = useStyles();
    return (_jsxs("div", __assign({ className: classes.root }, { children: [_jsx(Avatar, { src: src, placeHolder: placeHolder, size: 's' }, void 0), _jsxs("div", __assign({ className: classes.nameContainer }, { children: [_jsx(Body, __assign({ component: 'span', variant: 'medium-700' }, { children: name }), void 0), customId && _jsx(Caption, __assign({ className: classes.customId }, { children: customId }), void 0)] }), void 0)] }), void 0));
};
