var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { makeStyles } from '@material-ui/core';
import { components, } from 'react-select';
import AsyncSelectComponent from 'react-select/async';
import cx from 'classnames';
import { useState } from 'react';
import { Avatar, Body, Icons, Text, Checkbox, } from '../..';
import { useStyles as useDropdownStyles, selectStyles } from './styles/Dropdown.styles';
import { FormControl } from '../FormControl/FormControl';
import useAutocompleteStyles from './styles/Autocomplete.styles';
var useStyles = makeStyles(function () { return ({
    optionContainer: {
        display: 'flex !important',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    checkIcon: {
        color: 'green',
        right: 10,
        textAlign: 'right',
        marginLeft: 10,
    },
}); });
var AsyncSelect = function (_a) {
    var hiddenLabel = _a.hiddenLabel, _b = _a.locale, locale = _b === void 0 ? {
        label: 'Label', errorMessage: 'Error', placeholder: 'Select...', noOptionMsg: 'No Options',
    } : _b, _c = _a.testId, testId = _c === void 0 ? 'async-autocomplete' : _c, _d = _a.labelClassName, labelClassName = _d === void 0 ? '' : _d, _e = _a.className, className = _e === void 0 ? '' : _e, _f = _a.error, error = _f === void 0 ? false : _f, _g = _a.labelId, labelId = _g === void 0 ? '' : _g, _h = _a.loadOptions, loadOptions = _h === void 0 ? function () { } : _h, defaultOptions = _a.defaultOptions, required = _a.required, options = _a.options, value = _a.value, _j = _a.withIcon, withIcon = _j === void 0 ? false : _j, _k = _a.isLoading, isLoading = _k === void 0 ? false : _k, _l = _a.isDisabled, isDisabled = _l === void 0 ? false : _l, _m = _a.onChange, onChange = _m === void 0 ? function () { } : _m, _o = _a.onBlur, onBlur = _o === void 0 ? function () { } : _o, _p = _a.onFocus, onFocus = _p === void 0 ? function () { } : _p, _q = _a.onInputChange, onInputChange = _q === void 0 ? function () { } : _q, containerClassname = _a.containerClassname, _r = _a.isClearable, isClearable = _r === void 0 ? false : _r, isMulti = _a.isMulti, maxMenuHeight = _a.maxMenuHeight, rest = __rest(_a, ["hiddenLabel", "locale", "testId", "labelClassName", "className", "error", "labelId", "loadOptions", "defaultOptions", "required", "options", "value", "withIcon", "isLoading", "isDisabled", "onChange", "onBlur", "onFocus", "onInputChange", "containerClassname", "isClearable", "isMulti", "maxMenuHeight"]);
    var stylesFromDropdown = useDropdownStyles({});
    var stylesFromAutocomplete = useAutocompleteStyles();
    var _s = __read(useState(false), 2), isActive = _s[0], setIsActive = _s[1];
    var styles = useStyles();
    var handleFocus = function () {
        setIsActive(true);
    };
    var handeBlur = function () {
        setIsActive(false);
    };
    var renderOption = function (option, isSelected) {
        if (option.isAction) {
            return (_jsxs("div", __assign({ className: stylesFromAutocomplete.optionWithIcon }, { children: [option === null || option === void 0 ? void 0 : option.icon, _jsx(Text, __assign({ "aria-label": 'autocomplete-option-label', size: 15, weight: 700, className: stylesFromAutocomplete.actionOption }, { children: option.label }), void 0)] }), void 0));
        }
        if (withIcon) {
            var avatarProps = {};
            if (option === null || option === void 0 ? void 0 : option.src)
                avatarProps.src = option === null || option === void 0 ? void 0 : option.src;
            else
                avatarProps.placeHolder = option === null || option === void 0 ? void 0 : option.label;
            return (_jsxs("div", __assign({ className: stylesFromAutocomplete.optionWithIcon }, { children: [isMulti && _jsx(Checkbox, { checked: isSelected, onChange: function () { return null; } }, void 0), _jsxs("div", __assign({ className: stylesFromAutocomplete.optionWithIconFlexDiv }, { children: [(option === null || option === void 0 ? void 0 : option.icon)
                                ? option === null || option === void 0 ? void 0 : option.icon
                                : _jsx(Avatar, __assign({ size: 'm' }, avatarProps, { "aria-label": 'autocomplete-option-avatar' }), void 0), _jsxs("div", __assign({ className: stylesFromAutocomplete.optionWithIconTextContainer }, { children: [_jsx(Text, __assign({ "aria-label": 'autocomplete-option-label', size: 15, weight: 700 }, { children: option.label }), void 0), option.secondary
                                        && (_jsx(Body, __assign({ className: stylesFromAutocomplete.optionWithIconSecondaryText, component: 'span', variant: 'large', weight: 500, "aria-label": 'autocomplete-option-secondary-text' }, { children: option.secondary }), void 0))] }), void 0)] }), void 0), option.tag && option.tag] }), void 0));
        }
        return option.label;
    };
    return (_jsx("div", __assign({ className: containerClassname }, { children: _jsx(FormControl, __assign({ active: isActive, hiddenLabel: hiddenLabel, error: error, errorMessage: locale.errorMessage, id: labelId + "-" + String(locale === null || locale === void 0 ? void 0 : locale.label), required: required, label: locale === null || locale === void 0 ? void 0 : locale.label }, { children: _jsx(AsyncSelectComponent, __assign({}, rest, { cacheOptions: true, maxMenuHeight: maxMenuHeight, className: cx(stylesFromDropdown.select, className), styles: selectStyles, "data-testid": testId, value: value, onChange: onChange, onFocus: handleFocus, onInputChange: onInputChange, onBlur: handeBlur, loadOptions: loadOptions, options: options, isLoading: isLoading, isDisabled: isDisabled, defaultOptions: defaultOptions, isClearable: isClearable, placeholder: locale.placeholder, isMulti: isMulti, components: {
                    NoOptionsMessage: function (props) { return (_jsx(components.NoOptionsMessage, __assign({}, props, { children: locale.noOptionMsg }), void 0)); },
                    DropdownIndicator: function (props) { return (_jsx(components.DropdownIndicator, __assign({}, props, { children: _jsx(Icons.DropdownIcon, { className: stylesFromDropdown.dropdownIndicator }, void 0) }), void 0)); },
                    Option: function (props) {
                        var data = props.data, isSelected = props.isSelected;
                        return (_jsxs(components.Option, __assign({}, props, { className: styles.optionContainer }, { children: [renderOption(data, isSelected), isSelected && !isMulti && _jsx(Icons.GreenCheck, { className: styles.checkIcon }, void 0)] }), void 0));
                    },
                } }), void 0) }), void 0) }), void 0));
};
export default AsyncSelect;
