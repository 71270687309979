var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { makeStyles } from '@material-ui/core';
import cx from 'classnames';
import { Dialog } from './Base';
var useStyles = makeStyles(function (theme) { return ({
    root: {
        '& .MuiDialogActions-root button': {
            width: 176,
        },
    },
    paperRoot: {
        borderRadius: 0,
    },
    text: {
        color: theme.palette.grey[700],
    },
}); });
export var FullScreenDialog = function (_a) {
    var _b;
    var children = _a.children, props = __rest(_a, ["children"]);
    var locale = props.locale;
    var classes = useStyles();
    return (_jsx(Dialog, __assign({}, props, { fullScreen: true, className: classes.root, paperClassName: cx(classes.paperRoot, (_b = {},
            _b[String(props.paperClassName)] = !!props.paperClassName,
            _b)), headerType: 'navigationBar', locale: locale }, { children: children }), void 0));
};
