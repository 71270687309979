var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { Input } from '../../Input';
import { CalendarFilled } from '../../icons';
import { useInputStyles } from '../styles/DOBPicker.styles';
var DOBInput = forwardRef(function (_a, customRef) {
    var value = _a.value, onClick = _a.onClick, props = __rest(_a, ["value", "onClick"]);
    var styles = useInputStyles();
    return (_jsx(Input, __assign({}, props, { "aria-label": 'DOBInput', value: value, onClick: onClick, onChange: function () { }, ref: customRef, containerClassName: styles.calendarInput, endAdornment: _jsx(CalendarFilled, { className: styles.calendarEndAdornment }, void 0), autoComplete: 'off' }), void 0));
});
DOBInput.propTypes = {
    value: PropTypes.string,
    onClick: PropTypes.func,
};
export default DOBInput;
