var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import cx from 'classnames';
import useStyles from './styles';
var Check = function (props) {
    var className = props.className, rest = __rest(props, ["className"]);
    return (_jsx("span", __assign({ className: cx(useStyles().jobDoneIcon, className) }, rest, { children: _jsx("svg", __assign({ width: '1em', height: '1em', viewBox: '0 0 24 24', fill: 'currentColor', xmlns: 'http://www.w3.org/2000/svg' }, { children: _jsx("path", { fillRule: 'evenodd', clipRule: 'evenodd', d: 'M20.7243 5.31052C21.1051 5.71054 21.0895 6.34352 20.6895 6.72431L9.13394 17.7243C8.9366 17.9122 8.67116 18.0114 8.399 17.999C8.12683 17.9866 7.8715 17.8637 7.69204 17.6587L3.24759 12.5818C2.88381 12.1662 2.92578 11.5344 3.34133 11.1707C3.75688 10.8069 4.38865 10.8488 4.75243 11.2644L8.51013 15.5568L19.3105 5.2757C19.7106 4.89491 20.3435 4.9105 20.7243 5.31052Z', fill: 'currentColor' }, void 0) }), void 0) }), void 0));
};
export default Check;
