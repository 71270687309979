import { makeStyles } from '@material-ui/core';
var bgColor = '#ffffff';
var inputFontColor = '#16243D';
var useStyles = makeStyles(function (theme) { return ({
    root: {
        width: 'fit-content',
        '&:hover $input': {
            backgroundColor: bgColor,
            borderColor: '#B4B8C0',
        },
    },
    container: {
        width: 'fit-content',
    },
    input: {
        boxSizing: 'border-box',
        background: theme.palette.secondary.main,
        borderRadius: '6px',
        border: '2px solid transparent',
        height: 40,
        width: '40px !important',
        color: inputFontColor,
        fontWeight: 'bold',
        fontSize: '24px',
        fontFamily: [
            'Manrope',
            '"Segoe UI"',
            'Tahoma',
            'Geneva',
            'Verdana',
            'sans-serif',
        ].join(','),
        lineHeight: '32px',
        transition: '300ms ease-out',
        '&:focus': {
            outline: 'none',
            borderColor: theme.palette.primary.main + " !important",
            backgroundColor: bgColor + " !important",
        },
    },
    errorStyles: {
        borderColor: theme.palette.error.main,
        background: bgColor,
    },
    successStyles: {
        borderColor: theme.palette.success.main + " !important",
        background: bgColor + " !important",
    },
    errorMessage: {
        fontSize: 13,
        fontWeight: 500,
        marginTop: 6,
        color: theme.palette.error.main,
        textAlign: 'left',
    },
}); });
export default useStyles;
