import { createTheme, responsiveFontSizes } from '@material-ui/core/styles';
var theme = createTheme({
    typography: {
        fontFamily: [
            'Manrope',
            'Roboto',
            '"Nunito Sans"',
            '"Helvetica Neue"',
            'sans-serif',
            '-apple-system',
            'BlinkMacSystemFont',
            'Arial',
            '"Segoe UI"',
        ].join(','),
    },
    palette: {
        primary: {
            main: '#206ae9',
            light: '#1458cc',
            dark: '#0f4095',
        },
        secondary: {
            main: '#f5f7fa',
            light: '#ecf6ff',
            dark: '#daecff',
        },
        grey: {
            100: '#F5F7FA',
            300: '#DADDE4',
            400: '#B4B8C0',
            500: '#7F8897',
            700: '#5C627A',
        },
        error: {
            main: '#FF333D',
            light: '#FFF6F5',
        },
        success: {
            main: '#0E956F',
        },
        common: {
            white: '#fff',
            black: '#16243D',
        },
    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 900,
            lg: 1280,
            xl: 1536,
        },
    },
});
export default responsiveFontSizes(theme);
