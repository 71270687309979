var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { makeStyles } from '@material-ui/core';
import cx from 'classnames';
import { Icons, Button } from '../..';
import { MultipleAvatar } from '../Table';
import { Body, Caption } from '../Typography';
var useStyles = makeStyles(function (theme) { return ({
    container: {
        padding: 16,
        backgroundColor: theme.palette.grey[100],
        borderRadius: 16,
    },
    topContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    assignedTeamsContainer: {
        display: 'flex',
        alignItems: 'center',
        gap: 8,
        marginTop: 4,
    },
    actions: {
        display: 'flex',
        gap: 4,
    },
    assignedLabel: {
        color: theme.palette.grey[500],
    },
    note: {
        marginTop: 4,
        color: theme.palette.grey[700],
        wordBreak: 'break-word',
    },
    title: {
        wordBreak: 'break-word',
    },
    deleteButton: {
        color: theme.palette.grey[500],
        marginTop: 4,
        '& svg': {
            width: 20,
            height: 20,
        },
    },
}); });
var Note = function (_a) {
    var style = _a.style, className = _a.className, title = _a.title, locale = _a.locale, note = _a.note, onClickEdit = _a.onClickEdit, onClickDelete = _a.onClickDelete, avatarPropList = _a.avatarPropList, rest = __rest(_a, ["style", "className", "title", "locale", "note", "onClickEdit", "onClickDelete", "avatarPropList"]);
    var classes = useStyles();
    return (_jsxs("div", __assign({ style: style, className: cx(classes.container, className) }, rest, { children: [_jsxs("div", __assign({ className: classes.topContainer }, { children: [_jsxs("div", { children: [_jsx(Body, __assign({ variant: 'large-700', className: classes.title }, { children: title }), void 0), _jsxs("div", __assign({ className: classes.assignedTeamsContainer }, { children: [_jsxs(Caption, __assign({ className: classes.assignedLabel }, { children: [locale.assigned, ":"] }), void 0), _jsx(MultipleAvatar, { avatarPropList: avatarPropList }, void 0)] }), void 0)] }, void 0), _jsxs("div", __assign({ className: classes.actions }, { children: [onClickEdit && (_jsx(Button, { color: 'ghost', icon: _jsx(Icons.EditFilled, {}, void 0), onClick: onClickEdit, size: 'small', variant: 'fixedIcon' }, void 0)), onClickDelete && (_jsx(Button, { color: 'ghost', icon: _jsx(Icons.Trash, { className: classes.deleteButton }, void 0), onClick: onClickDelete, size: 'small', variant: 'fixedIcon' }, void 0))] }), void 0)] }), void 0), _jsx(Body, __assign({ variant: 'medium', className: classes.note }, { children: note }), void 0)] }), void 0));
};
export default Note;
