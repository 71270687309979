var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { makeStyles } from '@material-ui/core';
import { Avatar } from '../../Avatar';
import { Body } from '../../Typography';
var useStyles = makeStyles(function (theme) { return ({
    root: {
        display: 'flex',
        alignItems: 'center',
    },
    avatarContainer: {
        display: 'flex',
        alignItems: 'center',
    },
    avatar: {
        boxSizing: 'border-box',
        width: 29,
        height: 29,
        border: "2px solid " + theme.palette.common.white,
    },
    more: {
        transform: 'translateX(-22px)',
    },
}); });
export var MultipleAvatar = function (_a) {
    var _b = _a.avatarPropList, avatarPropList = _b === void 0 ? [
        {
            src: 'https://source.unsplash.com/random',
            size: 's',
        },
        {
            src: 'https://source.unsplash.com/random',
            size: 's',
        },
        {
            src: 'https://source.unsplash.com/random',
            size: 's',
        },
        {
            src: 'https://source.unsplash.com/random',
            size: 's',
        },
        {
            src: 'https://source.unsplash.com/random',
            size: 's',
        },
        {
            src: 'https://source.unsplash.com/random',
            size: 's',
        },
    ] : _b;
    var classes = useStyles();
    return (_jsxs("div", __assign({ className: classes.avatarContainer }, { children: [avatarPropList.slice(0, 4).map(function (avatarProps, i) { return (_jsx(Avatar, __assign({ className: classes.avatar, style: { transform: "translateX(-" + i * 10 + "px)" } }, avatarProps), String(avatarProps.src) + "-" + i)); }), avatarPropList.length > 4 && (_jsxs(Body, __assign({ className: classes.more, display: 'inline-block', variant: 'medium-700' }, { children: ["+", avatarPropList.length - 4] }), void 0))] }), void 0));
};
