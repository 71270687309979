import { makeStyles } from '@material-ui/core';
var useStyles = makeStyles(function (theme) { return ({
    element: {
        display: 'flex',
        backgroundColor: 'unset',
        borderRadius: 8,
        padding: '8px 12px',
        border: 'none',
        cursor: 'pointer',
        fontSize: 15,
        fontWeight: 500,
        transition: theme.transitions.create(['background-color', 'color']),
        fontFamily: theme.typography.fontFamily,
        color: theme.palette.common.black,
        '&:hover': {
            backgroundColor: theme.palette.grey[100],
        },
        '&:active': {
            backgroundColor: '#ECF6FF',
            color: theme.palette.primary.main,
        },
    },
    firstElement: {
        fontWeight: 700,
        fontSize: 15,
    },
    elementActive: {
        color: theme.palette.grey[500],
    },
}); });
export default useStyles;
