export var dateTypeEnum;
(function (dateTypeEnum) {
    dateTypeEnum["YEAR"] = "YEAR";
    dateTypeEnum["MONTH"] = "MONTH";
    dateTypeEnum["DATE"] = "DATE";
})(dateTypeEnum || (dateTypeEnum = {}));
export var hierarchyLevels;
(function (hierarchyLevels) {
    hierarchyLevels["BUSINESS"] = "BUSINESS";
    hierarchyLevels["GROUP"] = "GROUP";
    hierarchyLevels["TEAM"] = "TEAM";
})(hierarchyLevels || (hierarchyLevels = {}));
export var rowType;
(function (rowType) {
    rowType["SHIFT"] = "SHIFT";
    rowType["ABSENCE"] = "ABSENCE";
})(rowType || (rowType = {}));
export var sortingTypes;
(function (sortingTypes) {
    sortingTypes["A_TO_Z"] = "A_TO_Z";
    sortingTypes["Z_TO_A"] = "Z_TO_A";
    sortingTypes["NO_SORTING"] = "NO_SORTING";
})(sortingTypes || (sortingTypes = {}));
export var rangePickerVariantEnum;
(function (rangePickerVariantEnum) {
    rangePickerVariantEnum["TODAY"] = "TODAY";
    rangePickerVariantEnum["1_WEEK"] = "1_WEEK";
    rangePickerVariantEnum["2_WEEK"] = "2_WEEK";
    rangePickerVariantEnum["1_MONTH"] = "1_MONTH";
    rangePickerVariantEnum["1_YEAR"] = "1_YEAR";
    rangePickerVariantEnum["1_QUARTER"] = "1_QUARTER";
    rangePickerVariantEnum["CUSTOM"] = "CUSTOM";
})(rangePickerVariantEnum || (rangePickerVariantEnum = {}));
export var rangePickerInputActionTypeEnum;
(function (rangePickerInputActionTypeEnum) {
    rangePickerInputActionTypeEnum["PREV"] = "PREV";
    rangePickerInputActionTypeEnum["NEXT"] = "NEXT";
})(rangePickerInputActionTypeEnum || (rangePickerInputActionTypeEnum = {}));
export var ConfirmationStatusEnum;
(function (ConfirmationStatusEnum) {
    ConfirmationStatusEnum["open"] = "open";
    ConfirmationStatusEnum["approved"] = "approved";
    ConfirmationStatusEnum["rejected"] = "rejected";
})(ConfirmationStatusEnum || (ConfirmationStatusEnum = {}));
