var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { makeStyles } from '@material-ui/styles';
import { CountdownCircleTimer } from 'react-countdown-circle-timer';
var useStyles = makeStyles(function () { return ({
    root: {
        display: 'flex',
        justifyContent: 'center',
    },
    counter: {
        fontSize: 60,
        fontWeight: 700,
    },
}); });
export var CircleCountdown = function (_a) {
    var _b = _a.size, size = _b === void 0 ? 216 : _b, _c = _a.onComplete, onComplete = _c === void 0 ? function () { } : _c, _d = _a.autoStart, autoStart = _d === void 0 ? true : _d, _e = _a.duration, duration = _e === void 0 ? 5 : _e, _f = _a.colors, colors = _f === void 0 ? ['#206AE9', '#FF333D'] : _f;
    var classes = useStyles();
    return (_jsx("div", __assign({ className: classes.root }, { children: _jsx(CountdownCircleTimer, __assign({ isPlaying: autoStart, duration: duration, colors: colors, colorsTime: [5, 0], isSmoothColorTransition: true, size: size, onComplete: onComplete }, { children: function (_a) {
                var remainingTime = _a.remainingTime;
                return (_jsx("div", __assign({ className: classes.counter }, { children: remainingTime }), void 0));
            } }), void 0) }), void 0));
};
