var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { forwardRef, useRef, useEffect, useState, } from 'react';
import cx from 'classnames';
import { InputBase } from '@material-ui/core';
import useStyles from './styles/Input.styles';
import ErrorWithExclamation from '../icons/ErrorWithExclamation';
import GreenCheck from '../icons/GreenCheck';
import { useCombinedRefs } from '../../utils/helpers';
import '@emotion/core';
import Line from './Line';
import { Skeleton } from '../Skeleton';
import { FormControl } from '../FormControl/FormControl';
var Input = forwardRef(function (_a, ref) {
    var _b, _c;
    var _d = _a.passwordField, passwordField = _d === void 0 ? false : _d, _e = _a.testId, testId = _e === void 0 ? '' : _e, _f = _a.id, id = _f === void 0 ? 'test' : _f, _g = _a.className, className = _g === void 0 ? '' : _g, _h = _a.labelClassName, labelClassName = _h === void 0 ? '' : _h, _j = _a.locale, locale = _j === void 0 ? {
        errorMessage: '',
        label: 'Label',
        note: '',
        placeholder: 'Placeholder',
    } : _j, _k = _a.containerClassName, containerClassName = _k === void 0 ? '' : _k, _l = _a.error, error = _l === void 0 ? false : _l, _m = _a.success, success = _m === void 0 ? false : _m, _o = _a.endAdornment, endAdornment = _o === void 0 ? '' : _o, hiddenLabel = _a.hiddenLabel, _p = _a.multiline, multiline = _p === void 0 ? false : _p, _q = _a.css, css = _q === void 0 ? {} : _q, required = _a.required, _r = _a.loading, loading = _r === void 0 ? false : _r, onFocus = _a.onFocus, onBlur = _a.onBlur, props = __rest(_a, ["passwordField", "testId", "id", "className", "labelClassName", "locale", "containerClassName", "error", "success", "endAdornment", "hiddenLabel", "multiline", "css", "required", "loading", "onFocus", "onBlur"]);
    var innerRef = useRef(null);
    var _s = __read(useState(false), 2), isActive = _s[0], setIsActive = _s[1];
    var combinedRef = useCombinedRefs(innerRef, ref);
    var styles = useStyles();
    var inputClassNames = cx(styles.input, className, (_b = {},
        _b[styles.inputError] = error,
        _b[styles.inputSuccess] = success,
        _b));
    var lineClassNames = cx(styles.line, (_c = {},
        _c[styles.lineError] = error,
        _c[styles.lineSuccess] = success,
        _c));
    var containerClassNames = cx(styles.container, containerClassName);
    var renderEndAdornment = function () {
        if (passwordField && endAdornment && error) {
            return (_jsxs("div", __assign({ className: styles.errorTypePassowrdContainer }, { children: [endAdornment, _jsx(ErrorWithExclamation, { "data-testid": 'input-password-error-exclamation-icon', className: styles.errorIcon }, void 0)] }), void 0));
        }
        if (endAdornment) {
            return (_jsx("button", __assign({ className: styles.buttonAsDiv, onClick: function (e) {
                    e.preventDefault();
                    setIsActive(true);
                } }, { children: endAdornment }), void 0));
        }
        if (error)
            return _jsx(ErrorWithExclamation, { "data-testid": 'input-error-exclamation-icon', style: { marginRight: 14 } }, void 0);
        if (success)
            return _jsx(GreenCheck, { "data-testid": 'input-success-check-icon', style: { marginRight: 14 } }, void 0);
        return '';
    };
    var handleFocus = function (e) {
        onFocus === null || onFocus === void 0 ? void 0 : onFocus(e);
        setIsActive(true);
    };
    var handeBlur = function (e) {
        onBlur === null || onBlur === void 0 ? void 0 : onBlur(e);
        setIsActive(false);
    };
    useEffect(function () {
        var node = combinedRef.current;
        if (node) {
            var container = node.parentElement;
            var inputContainer = container === null || container === void 0 ? void 0 : container.children[1];
            var input = inputContainer === null || inputContainer === void 0 ? void 0 : inputContainer.firstChild;
            if (input) {
                if (input.disabled) {
                    inputContainer === null || inputContainer === void 0 ? void 0 : inputContainer.classList.add(styles.containerOnInputDisabled);
                }
                else {
                    inputContainer === null || inputContainer === void 0 ? void 0 : inputContainer.classList.remove(styles.containerOnInputDisabled);
                }
            }
        }
    }, [ref, combinedRef, innerRef, styles.containerOnInputDisabled]);
    if (loading) {
        return (_jsxs("div", __assign({ className: containerClassNames }, { children: [_jsx(FormControl, { hiddenLabel: hiddenLabel, id: id + "-" + String(locale === null || locale === void 0 ? void 0 : locale.label), required: required, label: locale === null || locale === void 0 ? void 0 : locale.label }, void 0), _jsx(Skeleton, { className: styles.skeletonInputContainer, variant: 'rect' }, void 0)] }), void 0));
    }
    return (_jsx("div", __assign({ className: containerClassNames }, { children: _jsxs(FormControl, __assign({ active: isActive, hiddenLabel: hiddenLabel, error: error, errorMessage: locale.errorMessage, success: success, id: id + "-" + String(locale === null || locale === void 0 ? void 0 : locale.label), required: required, label: locale === null || locale === void 0 ? void 0 : locale.label }, { children: [locale.note && (_jsx("span", { className: styles.note, 
                    // eslint-disable-next-line react/no-danger
                    dangerouslySetInnerHTML: { __html: locale.note } }, void 0)), _jsx(InputBase, __assign({}, props, { css: {}, ref: combinedRef, className: inputClassNames, id: id, onFocus: handleFocus, onBlur: handeBlur, "data-testid": testId, placeholder: locale === null || locale === void 0 ? void 0 : locale.placeholder, error: error, endAdornment: !multiline && renderEndAdornment(), multiline: multiline }), void 0), multiline && _jsx(Line, { className: lineClassNames }, void 0)] }), void 0) }), void 0));
});
export default Input;
