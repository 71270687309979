var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { MenuItem, MenuList, Popover, } from '@material-ui/core';
import cx from 'classnames';
import useStyles from './styles/DropdownMenu.styles';
export function DropdownMenu(_a) {
    var _b, _c;
    var anchorEl = _a.anchorEl, open = _a.open, handleClose = _a.handleClose, className = _a.className, paperClassName = _a.paperClassName, children = _a.children, _d = _a.anchorOrigin, anchorOrigin = _d === void 0 ? { horizontal: 'right', vertical: 'bottom' } : _d, props = __rest(_a, ["anchorEl", "open", "handleClose", "className", "paperClassName", "children", "anchorOrigin"]);
    var styles = useStyles();
    var classes = cx((_b = {},
        _b["" + String(className)] = !!className,
        _b));
    var paperClasses = cx(styles.paper, (_c = {},
        _c["" + String(paperClassName)] = !!paperClassName,
        _c));
    return (_jsx(Popover, __assign({ disableScrollLock: true, anchorEl: anchorEl, open: Boolean(anchorEl), onClose: handleClose, onClick: handleClose, transformOrigin: { horizontal: 'right', vertical: 'top' }, anchorOrigin: anchorOrigin, classes: {
            root: classes,
            paper: paperClasses,
        } }, props, { children: _jsx(MenuList, { children: children }, void 0) }), void 0));
}
DropdownMenu.Item = function Item(_a) {
    var children = _a.children, props = __rest(_a, ["children"]);
    var styles = useStyles();
    return (_jsx(MenuItem, __assign({}, props, { classes: { root: styles.listItem } }, { children: children }), void 0));
};
