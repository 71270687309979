import { makeStyles } from '@material-ui/core';
var useStyles = makeStyles(function (theme) { return ({
    container: {
        display: 'flex',
        gap: '8px',
        alignItems: 'center',
        width: 104,
        height: 40,
        background: theme.palette.grey[100],
        border: 'none',
        borderRadius: '8px',
        padding: '6px 4px',
    },
    countText: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: 28,
        width: 40,
        fontWeight: 500,
        fontSize: '14px',
        lineHeight: '24px',
        textAlign: 'center',
    },
    input: {
        height: 28,
        width: 40,
        cursor: 'pointer',
        outline: 'none',
        textAlign: 'center',
        fontWeight: 500,
        fontSize: '14px',
        lineHeight: '24px',
        borderRadius: '6px',
        border: "1px solid " + theme.palette.grey[400],
        background: theme.palette.common.white,
    },
    button: {
        fontSize: 15,
        fontWeight: 500,
        outline: 'none',
        border: 'none',
        width: 20,
        height: 20,
        borderRadius: '4px',
        color: theme.palette.primary.main,
        background: 'transparent',
        cursor: 'pointer',
        transition: theme.transitions.create(['background', 'color']),
        '&:hover': {
            background: theme.palette.primary.main,
            color: theme.palette.common.white,
        },
        '&:active': {
            background: theme.palette.primary.dark,
            color: theme.palette.common.white,
        },
        '&:disabled': {
            background: 'transparent',
            cursor: 'not-allowed',
            color: theme.palette.grey[400],
        },
    },
}); });
export default useStyles;
