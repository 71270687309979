var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import cx from 'classnames';
import MUIButton from '@material-ui/core/Button';
import { Loading } from './Loading';
import useStyles from './styles/Button.styles';
import { Variants } from './utils';
export function Button(_a) {
    var _b;
    var variant = _a.variant, color = _a.color, size = _a.size, disabled = _a.disabled, loading = _a.loading, className = _a.className, onClick = _a.onClick, label = _a.label, icon = _a.icon, testId = _a.testId, hasFlash = _a.hasFlash, props = __rest(_a, ["variant", "color", "size", "disabled", "loading", "className", "onClick", "label", "icon", "testId", "hasFlash"]);
    var selectedVariant = variant || 'fixedLabel';
    var selectedColor = color || 'primary';
    var selectedSize = size || 'medium';
    var styles = useStyles();
    var classes = cx(styles.root, styles[selectedVariant], styles[selectedColor], styles[selectedVariant + "_" + String(selectedSize)], (_b = {},
        _b["" + String(className)] = !!className,
        _b[styles.flash] = hasFlash,
        _b));
    var renderFixedIcon = function () {
        if (icon) {
            if (loading)
                return _jsx(Loading, { color: selectedColor, hasLabel: false }, void 0);
            return _jsx(_Fragment, { children: icon }, void 0);
        }
        return _jsx(_Fragment, { children: ' ' }, void 0);
    };
    var renderFixedLabel = function () {
        if (label) {
            if (loading) {
                return (_jsxs(_Fragment, { children: [_jsx(Loading, { color: selectedColor, hasLabel: true }, void 0), label] }, void 0));
            }
            return _jsx(_Fragment, { children: label }, void 0);
        }
        return _jsx(_Fragment, { children: ' ' }, void 0);
    };
    var renderAutoWidth = function () { return (_jsxs(_Fragment, { children: [icon, _jsx("span", __assign({ className: (icon && label) ? styles.marginLeft : '' }, { children: label }), void 0)] }, void 0)); };
    var renderContent = function () {
        switch (selectedVariant) {
            case Variants.autoWidth:
                return renderAutoWidth();
            case Variants.fixedIcon:
                return renderFixedIcon();
            case Variants.fixedLabel:
                return renderFixedLabel();
            default:
                return renderFixedLabel();
        }
    };
    var isDisabled = function () {
        if (selectedVariant === Variants.fixedIcon || selectedVariant === Variants.fixedLabel)
            return disabled || loading;
        return disabled;
    };
    return (_jsx(MUIButton, __assign({ "data-testid": testId, disableRipple: true, disabled: isDisabled(), onClick: onClick, classes: {
            root: classes,
        } }, props, { children: renderContent() }), void 0));
}
