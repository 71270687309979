import { makeStyles } from '@material-ui/core/styles';
var useStyles = makeStyles(function (theme) { return ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: 24,
        border: "2px dashed " + theme.palette.grey[400],
        borderRadius: 8,
        transition: '0.1s',
        '&:hover': {
            borderColor: theme.palette.primary.main,
        },
    },
    dragging: {
        backgroundColor: '#DAECFF',
        borderColor: theme.palette.primary.main,
    },
    image: {
        width: 40,
        height: 40,
    },
    title: {
        marginTop: 16,
    },
    description: {
        marginTop: 4,
        '&>span': {
            color: theme.palette.primary.main,
            textDecoration: 'underline',
        },
    },
    input: {
        position: 'absolute',
        left: '0',
        top: '0',
        width: '1px',
        height: '1px',
        opacity: '0',
        cursor: 'default',
    },
}); });
export default useStyles;
