var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { makeStyles } from '@material-ui/core';
import { useState } from 'react';
import cx from 'classnames';
import { theme } from '../../../utils';
import { DropdownMenu } from '../../DropdownMenu';
import { Icons } from '../../..';
import { Button } from '../../Button';
import { Body } from '../../Typography';
import { MenuItemsTypeEnum } from '../../../interfaces';
var useStyles = makeStyles(function () { return ({
    button: {
        color: theme.palette.grey[500],
    },
    menuItem: {
        display: 'flex',
        gap: 10,
    },
    regular: {
        color: theme.palette.grey[500],
        '&:hover *': {
            color: theme.palette.primary.main,
        },
    },
    error: {
        '& > *': {
            color: theme.palette.error.main + " !important",
        },
        '&:hover': {
            backgroundColor: theme.palette.error.light,
        },
    },
}); });
export var ThreeDotsButton = function (_a) {
    var _b = _a.menuItems, menuItems = _b === void 0 ? [] : _b, classname = _a.classname;
    var classes = useStyles();
    var _c = __read(useState(null), 2), anchorEl = _c[0], setAnchorEl = _c[1];
    var handleClick = function (event) {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
    };
    var handleClose = function (event) {
        event.stopPropagation();
        setAnchorEl(null);
    };
    return (_jsxs(_Fragment, { children: [_jsx(Button, { className: cx(classes.button, classname), variant: 'fixedIcon', color: 'ghost', icon: _jsx(Icons.MoreHorizontal, {}, void 0), onClick: handleClick }, void 0), menuItems.length > 0 && (_jsx(DropdownMenu, __assign({ "aria-label": 'menu-items', anchorEl: anchorEl, open: Boolean(anchorEl), handleClose: handleClose }, { children: menuItems.map(function (_a) {
                    var _b;
                    var key = _a.key, label = _a.label, icon = _a.icon, type = _a.type, rest = __rest(_a, ["key", "label", "icon", "type"]);
                    return (_jsxs(DropdownMenu.Item, __assign({ className: cx(classes.menuItem, (_b = {},
                            _b[classes.regular] = type === MenuItemsTypeEnum.REGULAR,
                            _b[classes.error] = type === MenuItemsTypeEnum.ERROR,
                            _b)) }, rest, { children: [icon || _jsx(_Fragment, {}, void 0), _jsx(Body, __assign({ variant: 'large' }, { children: label }), void 0)] }), key));
                }) }), void 0))] }, void 0));
};
