var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Drawer as MUIDrawer, } from '@material-ui/core';
import cx from 'classnames';
import { createContext, useContext } from 'react';
import { useDropdownMenu } from '../../utils/helpers';
import { Button } from '../Button';
import { MoreHorizontal } from '../icons';
import CloseIcon from '../icons/Close';
import { Body, Header } from '../Typography';
import { DrawerMenuItems } from './MenuOptions';
import useStyles from './styles/Drawer.styles';
var DrawerContext = createContext(null);
export var Drawer = function (_a) {
    var children = _a.children, className = _a.className, open = _a.open, onClose = _a.onClose, discardButtonProps = _a.discardButtonProps, saveButtonProps = _a.saveButtonProps, publishButtonProps = _a.publishButtonProps, _b = _a.position, position = _b === void 0 ? 'right' : _b, _c = _a.hasFooter, hasFooter = _c === void 0 ? true : _c, _d = _a.size, size = _d === void 0 ? 'large' : _d, showMenuActions = _a.showMenuActions, menuItems = _a.menuItems, props = __rest(_a, ["children", "className", "open", "onClose", "discardButtonProps", "saveButtonProps", "publishButtonProps", "position", "hasFooter", "size", "showMenuActions", "menuItems"]);
    var styles = useStyles({
        saveButtonProps: !!saveButtonProps,
        size: size,
    });
    return (_jsx(DrawerContext.Provider, __assign({ value: {
            open: open,
            onClose: onClose,
            hasFooter: hasFooter,
            size: size,
            showMenuActions: showMenuActions,
            menuItems: menuItems,
        } }, { children: _jsxs(MUIDrawer, __assign({}, props, { open: open, onClose: onClose, anchor: position, className: cx(styles.drawerStyles, className) }, { children: [_jsx("div", __assign({ className: styles.container }, { children: children }), void 0), hasFooter && (_jsxs("div", __assign({ className: styles.footerSection }, { children: [!!discardButtonProps && (_jsx(Button, __assign({ color: 'ghost', size: 'large' }, discardButtonProps), void 0)), _jsxs("div", { children: [!!saveButtonProps && (_jsx(Button, __assign({ color: 'secondary', size: 'large', className: styles.saveButton }, saveButtonProps), void 0)), !!publishButtonProps && (_jsx(Button, __assign({ size: 'large' }, publishButtonProps), void 0))] }, void 0)] }), void 0))] }), void 0) }), void 0));
};
Drawer.Sidebar = function Sidebar(_a) {
    var children = _a.children, title = _a.title, icon = _a.icon, className = _a.className, showMenuActions = _a.showMenuActions, menuItems = _a.menuItems, props = __rest(_a, ["children", "title", "icon", "className", "showMenuActions", "menuItems"]);
    var styles = useStyles({});
    var _b = useDropdownMenu(), handleCloseMore = _b.handleClose, anchorEl = _b.anchorEl, handleClickMore = _b.handleClick;
    return (_jsxs("div", __assign({}, props, { className: cx(styles.navigationSection, className) }, { children: [_jsxs("div", __assign({ className: styles.sidebarHeader }, { children: [_jsxs("div", __assign({ className: styles.sidebarTitle }, { children: [icon && _jsx("span", __assign({ className: styles.icon }, { children: icon }), void 0), _jsx(Header, __assign({ variant: 'h1', weight: 700 }, { children: title }), void 0)] }), void 0), showMenuActions && (_jsx(Button, { variant: 'fixedIcon', color: 'ghost', className: styles.drawerMenuActions, onClick: handleClickMore, icon: _jsx(MoreHorizontal, {}, void 0) }, void 0)), _jsx(DrawerMenuItems, { handleClose: handleCloseMore, anchorEl: anchorEl, menuItems: menuItems }, void 0)] }), void 0), _jsx(_Fragment, { children: children }, void 0)] }), void 0));
};
Drawer.Main = function Main(_a) {
    var children = _a.children, title = _a.title, subtitle = _a.subtitle, className = _a.className, props = __rest(_a, ["children", "title", "subtitle", "className"]);
    var drawerContext = useContext(DrawerContext);
    var styles = useStyles({
        hasFooter: drawerContext === null || drawerContext === void 0 ? void 0 : drawerContext.hasFooter,
        size: drawerContext === null || drawerContext === void 0 ? void 0 : drawerContext.size,
    });
    var _b = useDropdownMenu(), handleCloseMore = _b.handleClose, anchorEl = _b.anchorEl, handleClickMore = _b.handleClick;
    var getMainSectionHeaderButton = function () {
        if (drawerContext === null || drawerContext === void 0 ? void 0 : drawerContext.showMenuActions) {
            return (_jsx(Button, { variant: 'fixedIcon', color: 'ghost', className: styles.drawerMenuActions, onClick: handleClickMore, icon: _jsx(MoreHorizontal, {}, void 0) }, void 0));
        }
        return (_jsx(Button, { variant: 'fixedIcon', color: 'ghost', onClick: function () { var _a; return (_a = drawerContext === null || drawerContext === void 0 ? void 0 : drawerContext.onClose) === null || _a === void 0 ? void 0 : _a.call(drawerContext, {}, 'escapeKeyDown'); }, icon: (_jsx(CloseIcon, { "aria-label": 'drawer-close-icon', className: styles.closeIcon }, void 0)) }, void 0));
    };
    return (_jsxs("div", __assign({}, props, { className: cx(styles.mainSection, className), id: 'mainSection' }, { children: [_jsxs("div", __assign({ className: styles.mainTitle }, { children: [_jsxs("div", { children: [_jsx(Header, __assign({ variant: 'h1', weight: 700, id: 'mainHeader' }, { children: title }), void 0), subtitle && (_jsx(Body, __assign({ variant: 'medium', weight: 500, id: 'mainSubHeader', className: styles.subtitle }, { children: subtitle }), void 0))] }, void 0), getMainSectionHeaderButton(), _jsx(DrawerMenuItems, { handleClose: handleCloseMore, anchorEl: anchorEl, menuItems: drawerContext === null || drawerContext === void 0 ? void 0 : drawerContext.menuItems }, void 0)] }), void 0), _jsx(_Fragment, { children: children }, void 0)] }), void 0));
};
