export var SIZES = {
    xs: 'xs',
    s: 's',
    m: 'm',
    l: 'l',
    xl: 'xl',
    xxl: 'xxl',
};
export var BADGES = {
    absent: 'absent',
    onBreak: 'onBreak',
    atWork: 'atWork',
    approved: 'approved',
    awaitingApproval: 'awaitingApproval',
    folder: 'folder',
};
export var TYPES = {
    rainbow: 'rainbow',
    dotted: 'dashed',
    default: 'default',
};
export var getPlaceHolderText = function (placeHolder) {
    if (!placeHolder || typeof placeHolder !== 'string')
        return undefined;
    return placeHolder
        .replace(/\s+/, ' ')
        .split(' ')
        .slice(0, 2)
        .map(function (v) { return v && v[0].toUpperCase(); })
        .join('');
};
