var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { makeStyles } from '@material-ui/core/styles';
import { Chip as ChipMUI } from '@material-ui/core';
import cx from 'classnames';
import { Icons } from '../..';
import { theme } from '../../utils';
var useStyles = makeStyles(function () { return ({
    root: function () { return ({
        padding: '4px 8px',
        borderRadius: 8,
        fontFamily: theme.typography.fontFamily,
        fontStyle: 'normal',
        fontWeight: 700,
        fontSize: 14,
        lineHeight: '24px',
        backgroundColor: theme.palette.grey[100],
        color: theme.palette.common.black,
        height: 32,
        transition: '0.3s',
        '&:hover': {
            backgroundColor: theme.palette.grey[300],
        },
        '& .MuiChip-label': {
            paddingLeft: 0,
            paddingRight: 4,
        },
    }); },
    closeIcon: {
        margin: 0,
        '& > svg': {
            color: theme.palette.grey[500],
        },
    },
}); });
export var Chip = function (_a) {
    var _b = _a.label, label = _b === void 0 ? 'Option' : _b, className = _a.className, onDelete = _a.onDelete, props = __rest(_a, ["label", "className", "onDelete"]);
    var classes = useStyles();
    return (_jsx(ChipMUI, __assign({}, props, { className: cx(classes.root, className), label: label, deleteIcon: _jsx(Icons.Close, { className: classes.closeIcon }, void 0), onDelete: onDelete }), void 0));
};
