var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import cx from 'classnames';
import { InputLabel, Box } from '@material-ui/core';
import useStyles from './styles/FormControl.styles';
export var FormControl = function (_a) {
    var _b;
    var id = _a.id, label = _a.label, error = _a.error, active = _a.active, success = _a.success, required = _a.required, children = _a.children, className = _a.className, marginBottom = _a.marginBottom, errorMessage = _a.errorMessage, hiddenLabel = _a.hiddenLabel;
    var styles = useStyles();
    var labelClassNames = cx(styles.label, (_b = {},
        _b[styles.labelSuccess] = success,
        _b[styles.labelActive] = active,
        _b[styles.labelError] = error,
        _b), className);
    return (_jsxs(Box, __assign({ sx: { mb: marginBottom } }, { children: [!hiddenLabel && (_jsxs(InputLabel, __assign({ id: id, htmlFor: id, className: labelClassNames }, { children: [label, required && _jsx("span", __assign({ className: styles.dot }, { children: "\u2022" }), void 0)] }), void 0)), children, error && !!errorMessage && (_jsx("span", __assign({ className: styles.errorMessage }, { children: errorMessage }), void 0))] }), void 0));
};
