var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { makeStyles } from '@material-ui/core';
import { Body } from '../Typography';
import { Dialog } from './Base';
var useStyles = makeStyles(function (theme) { return ({
    text: {
        color: theme.palette.grey[700],
    },
}); });
export var ConfirmDialog = function (_a) {
    var props = __rest(_a, []);
    var locale = props.locale, maxWidth = props.maxWidth;
    var classes = useStyles();
    return (_jsx(Dialog, __assign({}, props, { maxWidth: maxWidth || 'xs', locale: __assign(__assign({}, locale), { content: (_jsx(Body, __assign({ variant: 'large', className: classes.text }, { children: locale.content }), void 0)) }) }), void 0));
};
