var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import cx from 'classnames';
import { ClickAwayListener } from '@material-ui/core';
import DatePickerComponent from 'react-datepicker';
import { useStyles } from '../styles/RangeTimePicker.styles';
import { getDateFnsLocaleFromJobDoneLocale, JobDoneLocales, useDropdownMenu, } from '../../../utils/helpers';
import 'react-datepicker/dist/react-datepicker.css';
import TimeInput from './TimeInput';
import { DropdownMenu } from '../../DropdownMenu';
var currentDate = new Date();
var startTime = new Date(currentDate.setHours(9, 0, 0, 0));
var endTime = new Date(currentDate.setHours(18, 0, 0, 0));
export var RangeTimePicker = function (_a) {
    var _b;
    var inputClassName = _a.inputClassName, date = _a.date, _c = _a.getChosenDate, getChosenDate = _c === void 0 ? function () { } : _c, _d = _a.inputLocale, inputLocale = _d === void 0 ? {
        errorMessage: '',
        label: '',
        placeholder: '',
    } : _d, _e = _a.pickerLocale, pickerLocale = _e === void 0 ? {
        start: 'Starts',
        end: 'Ends',
    } : _e, _f = _a.locale, locale = _f === void 0 ? JobDoneLocales.ENGLISH : _f, _g = _a.hasError, hasError = _g === void 0 ? false : _g, _h = _a.timeFormat, timeFormat = _h === void 0 ? 'HH:mm' : _h, props = __rest(_a, ["inputClassName", "date", "getChosenDate", "inputLocale", "pickerLocale", "locale", "hasError", "timeFormat"]);
    var _j = __read(useState({
        start: date ? dayjs(date.start).set('second', 0).toDate() : startTime,
        end: date ? dayjs(date.end).set('second', 0).toDate() : endTime,
    }), 2), pickerState = _j[0], setPickerState = _j[1];
    var _k = useDropdownMenu(), anchorEl = _k.anchorEl, handleClick = _k.handleClick, handleClose = _k.handleClose;
    var styles = useStyles();
    useEffect(function () {
        setPickerState({
            start: date ? dayjs(date.start).set('second', 0).toDate() : startTime,
            end: date ? dayjs(date.end).set('second', 0).toDate() : endTime,
        });
    }, [date]);
    var handleStartTimeChange = function (chosenDate) {
        setPickerState(__assign(__assign({}, pickerState), { start: chosenDate }));
        getChosenDate(__assign(__assign({}, pickerState), { start: dayjs(chosenDate).set('second', 0).toDate() }));
    };
    var handleEndTimeChange = function (chosenDate) {
        var updatedDate;
        var difference = dayjs(chosenDate).diff(dayjs(pickerState.start), 'minutes');
        if (difference === 0 || difference < 0) {
            updatedDate = dayjs(chosenDate).add(1, 'day').toDate();
        }
        else if (difference > 24 * 60) {
            updatedDate = dayjs(chosenDate).subtract(1, 'day').toDate();
        }
        else {
            updatedDate = chosenDate;
        }
        setPickerState(__assign(__assign({}, pickerState), { end: updatedDate }));
        getChosenDate(__assign(__assign({}, pickerState), { end: dayjs(updatedDate).set('second', 0).toDate() }));
        handleClose();
    };
    return (_jsxs(_Fragment, { children: [_jsx(TimeInput, { className: cx(inputClassName, (_b = {},
                    _b[styles.activeInput] = !!anchorEl,
                    _b)), error: hasError, locale: inputLocale, value: pickerState, timeFormat: timeFormat, onClick: handleClick }, void 0), _jsx(DropdownMenu, __assign({ className: styles.dropdown, anchorEl: anchorEl, open: Boolean(anchorEl), handleClose: function () { }, transitionDuration: 0 }, { children: _jsx(ClickAwayListener, __assign({ onClickAway: handleClose }, { children: _jsxs("div", __assign({ className: cx(styles.pickerContainer) }, { children: [_jsx(DatePickerComponent, __assign({}, props, { selected: pickerState.start, onChange: handleStartTimeChange, customInput: _jsx("input", { className: styles.displayNone }, void 0), calendarClassName: cx(styles.calendarRoot, styles.firstPickerRoot), showPopperArrow: false, showTimeSelect: true, showTimeSelectOnly: true, timeFormat: timeFormat, timeIntervals: 5, placeholderText: '', timeCaption: pickerLocale.start, locale: getDateFnsLocaleFromJobDoneLocale(locale), inline: true }), void 0), _jsx(DatePickerComponent, __assign({}, props, { selected: pickerState.end, onChange: handleEndTimeChange, customInput: _jsx("input", { className: styles.displayNone }, void 0), calendarClassName: cx(styles.calendarRoot, styles.secondPickerRoot), showPopperArrow: false, showTimeSelect: true, showTimeSelectOnly: true, timeFormat: timeFormat, timeIntervals: 5, placeholderText: '', timeCaption: pickerLocale.end, locale: getDateFnsLocaleFromJobDoneLocale(locale), inline: true }), void 0)] }), void 0) }), void 0) }), void 0)] }, void 0));
};
