var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import useStyles from './styles';
var GreenCheck = function (props) { return (_jsx("span", __assign({ className: useStyles().jobDoneIcon }, props, { children: _jsx("svg", __assign({ width: '18', height: '13', viewBox: '0 0 18 13', fill: 'none', xmlns: 'http://www.w3.org/2000/svg' }, { children: _jsx("path", { fillRule: 'evenodd', clipRule: 'evenodd', d: 'M17.7243 0.310521C18.1051 0.710542 18.0895 1.34352 17.6895 1.72431L6.13394 12.7243C5.9366 12.9122 5.67116 13.0114 5.399 12.999C5.12683 12.9866 4.8715 12.8637 4.69204 12.6587L0.247594 7.58176C-0.116187 7.16621 -0.0742203 6.53444 0.34133 6.17066C0.75688 5.80688 1.38865 5.84885 1.75243 6.26439L5.51013 10.5568L16.3105 0.275698C16.7106 -0.105092 17.3435 -0.0895011 17.7243 0.310521Z', fill: 'currentColor' }, void 0) }), void 0) }), void 0)); };
export default GreenCheck;
