var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import cx from 'classnames';
import useStyles from './styles';
var ArrowDropUp = function (props) {
    var className = props.className, rest = __rest(props, ["className"]);
    return (_jsx("span", __assign({ className: cx(useStyles().jobDoneIcon, className) }, rest, { children: _jsx("svg", __assign({ width: '24', height: '24', viewBox: '0 0 24 24', fill: 'none', xmlns: 'http://www.w3.org/2000/svg' }, { children: _jsx("path", { d: 'M11.2403 8.88583C11.6394 8.42022 12.3597 8.42022 12.7588 8.88583L16.5846 13.3492C17.1406 13.9979 16.6797 15 15.8254 15H8.17381C7.31945 15 6.85854 13.9979 7.41455 13.3492L11.2403 8.88583Z', fill: 'currentColor' }, void 0) }), void 0) }), void 0));
};
export default ArrowDropUp;
