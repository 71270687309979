var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
export var createImage = function (url) { return new Promise(function (resolve, reject) {
    var image = new Image();
    image.addEventListener('load', function () { return resolve(image); });
    image.addEventListener('error', function (error) { return reject(error); });
    image.setAttribute('crossOrigin', 'anonymous'); // needed to avoid cross-origin issues on CodeSandbox
    image.src = url;
}); };
export function getRadianAngle(degreeValue) {
    return (degreeValue * Math.PI) / 180;
}
/**
 * Returns the new bounding area of a rotated rectangle.
 */
export function rotateSize(width, height, rotation) {
    var rotRad = getRadianAngle(rotation);
    return {
        width: Math.abs(Math.cos(rotRad) * Number(width)) + Math.abs(Math.sin(rotRad) * Number(height)),
        height: Math.abs(Math.sin(rotRad) * Number(width)) + Math.abs(Math.cos(rotRad) * Number(height)),
    };
}
/**
 * This function was adapted from the one in the ReadMe of https://github.com/DominicTobias/react-image-crop
 */
export function getCroppedImg(imageSrc, pixelCrop, rotation, flip) {
    if (rotation === void 0) { rotation = 0; }
    if (flip === void 0) { flip = { horizontal: false, vertical: false }; }
    return __awaiter(this, void 0, void 0, function () {
        var image, canvas, ctx, rotRad, _a, bBoxWidth, bBoxHeight, data;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0: return [4 /*yield*/, createImage(imageSrc)];
                case 1:
                    image = _b.sent();
                    canvas = document.createElement('canvas');
                    ctx = canvas.getContext('2d');
                    if (!ctx) {
                        return [2 /*return*/, null];
                    }
                    rotRad = getRadianAngle(rotation);
                    _a = rotateSize(image.width, image.height, rotation), bBoxWidth = _a.width, bBoxHeight = _a.height;
                    // set canvas size to match the bounding box
                    canvas.width = bBoxWidth;
                    canvas.height = bBoxHeight;
                    // translate canvas context to a central location to allow rotating and flipping around the center
                    ctx.translate(bBoxWidth / 2, bBoxHeight / 2);
                    ctx.rotate(rotRad);
                    ctx.scale(flip.horizontal ? -1 : 1, flip.vertical ? -1 : 1);
                    ctx.translate(-image.width / 2, -image.height / 2);
                    // draw rotated image
                    ctx.drawImage(image, 0, 0);
                    data = ctx.getImageData(pixelCrop.x, pixelCrop.y, pixelCrop.width, pixelCrop.height);
                    // set canvas width to final desired crop size - this will clear existing context
                    canvas.width = pixelCrop.width;
                    canvas.height = pixelCrop.height;
                    // paste generated rotate image at the top left corner
                    ctx.putImageData(data, 0, 0);
                    // As Base64 string
                    // return canvas.toDataURL('image/jpeg');
                    // As a blob
                    return [2 /*return*/, new Promise(function (resolve) {
                            canvas.toBlob(function (file) { return resolve(URL.createObjectURL(file)); }, 'image/jpeg');
                        })];
            }
        });
    });
}
export var urltoFile = function (url, filename, mimeType) { return (fetch(url)
    .then(function (res) { return res.arrayBuffer(); })
    .then(function (buf) { return new File([buf], filename, { type: mimeType }); })); };
// export async function getRotatedImage(imageSrc, rotation = 0) {
//   const image = await createImage(imageSrc);
//   const canvas = document.createElement('canvas');
//   const ctx = canvas.getContext('2d');
//   const orientationChanged = rotation === 90 || rotation === -90 || rotation === 270 || rotation === -270;
//   if (orientationChanged) {
//     canvas.width = image.height;
//     canvas.height = image.width;
//   } else {
//     canvas.width = image.width;
//     canvas.height = image.height;
//   }
//   ctx.translate(canvas.width / 2, canvas.height / 2);
//   ctx.rotate((rotation * Math.PI) / 180);
//   ctx.drawImage(image, -image.width / 2, -image.height / 2);
//   return new Promise((resolve) => {
//     canvas.toBlob((file) => {
//       resolve(URL.createObjectURL(file));
//     }, 'image/png');
//   });
// }
