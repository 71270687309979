var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { forwardRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import { Icons } from '../../../index';
import { rangePickerInputActionTypeEnum } from '../../../utils/consts';
var useStyles = makeStyles(function (theme) { return ({
    root: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        '& > span': {
            cursor: 'pointer',
            color: theme.palette.common.black,
            fontFamily: 'Manrope',
            fontWeight: 700,
            fontSize: 14,
            userSelect: 'none',
            margin: '0 4px',
        },
    },
    arrow: {
        '& > svg': {
            color: theme.palette.grey[500],
        },
    },
    selectedValue: {
        color: theme.palette.primary.main + " !important",
    },
}); });
var Input = forwardRef(function (_a, ref) {
    var value = _a.value, onChange = _a.onChange, setChosenDatePeriod = _a.setChosenDatePeriod, isOneWeekSelected = _a.isOneWeekSelected, isOneMonthSelected = _a.isOneMonthSelected, isTwoWeekSelected = _a.isTwoWeekSelected, isOneDaySelected = _a.isOneDaySelected, chosenDatePeriod = _a.chosenDatePeriod, isOneYearSelected = _a.isOneYearSelected, isCustomSelected = _a.isCustomSelected, props = __rest(_a, ["value", "onChange", "setChosenDatePeriod", "isOneWeekSelected", "isOneMonthSelected", "isTwoWeekSelected", "isOneDaySelected", "chosenDatePeriod", "isOneYearSelected", "isCustomSelected"]);
    var classes = useStyles();
    // TODO: Refactor
    var handleClick = function (actionType) {
        var from = chosenDatePeriod.from, to = chosenDatePeriod.to;
        if (isOneWeekSelected) {
            if (actionType === rangePickerInputActionTypeEnum.PREV) {
                var updatedRange = {
                    from: new Date(from.setDate(from.getDate() - 7)),
                    to: new Date(to.setDate(to.getDate() - 7)),
                };
                setChosenDatePeriod === null || setChosenDatePeriod === void 0 ? void 0 : setChosenDatePeriod(updatedRange);
            }
            else {
                var updatedRange = {
                    from: new Date(from.setDate(+from.getDate() + 7)),
                    to: new Date(to.setDate(+to.getDate() + 7)),
                };
                setChosenDatePeriod === null || setChosenDatePeriod === void 0 ? void 0 : setChosenDatePeriod(updatedRange);
            }
        }
        if (isTwoWeekSelected) {
            if (actionType === rangePickerInputActionTypeEnum.PREV) {
                var updatedRange = {
                    from: new Date(from.setDate(from.getDate() - 14)),
                    to: new Date(to.setDate(to.getDate() - 14)),
                };
                setChosenDatePeriod === null || setChosenDatePeriod === void 0 ? void 0 : setChosenDatePeriod(updatedRange);
            }
            else {
                var updatedRange = {
                    from: new Date(from.setDate(+from.getDate() + 14)),
                    to: new Date(to.setDate(+to.getDate() + 14)),
                };
                setChosenDatePeriod === null || setChosenDatePeriod === void 0 ? void 0 : setChosenDatePeriod(updatedRange);
            }
        }
        if (isOneMonthSelected) {
            var currentMonth = from.getMonth();
            var currentYear = from.getFullYear();
            if (actionType === rangePickerInputActionTypeEnum.PREV) {
                var updatedRange = {
                    from: new Date(currentYear, currentMonth - 1, 1, 0, 0, 0),
                    to: new Date(currentYear, currentMonth, 0, 23, 59, 59),
                };
                setChosenDatePeriod === null || setChosenDatePeriod === void 0 ? void 0 : setChosenDatePeriod(updatedRange);
            }
            else {
                var updatedRange = {
                    from: new Date(currentYear, currentMonth + 1, 1, 0, 0, 0),
                    to: new Date(currentYear, currentMonth + 2, 0, 23, 59, 59),
                };
                setChosenDatePeriod === null || setChosenDatePeriod === void 0 ? void 0 : setChosenDatePeriod(updatedRange);
            }
        }
        if (isOneDaySelected) {
            if (actionType === rangePickerInputActionTypeEnum.PREV) {
                var updatedRange = {
                    from: new Date(from.setDate(from.getDate() - 1)),
                    to: new Date(to.setDate(to.getDate() - 1)),
                };
                setChosenDatePeriod === null || setChosenDatePeriod === void 0 ? void 0 : setChosenDatePeriod(updatedRange);
            }
            else {
                var updatedRange = {
                    from: new Date(from.setDate(+from.getDate() + 1)),
                    to: new Date(to.setDate(+to.getDate() + 1)),
                };
                setChosenDatePeriod === null || setChosenDatePeriod === void 0 ? void 0 : setChosenDatePeriod(updatedRange);
            }
        }
        if (isOneYearSelected) {
            var currentYear = from.getFullYear();
            if (actionType === rangePickerInputActionTypeEnum.PREV) {
                var updatedRange = {
                    from: new Date(currentYear - 1, 0, 1, 0, 0, 0),
                    to: new Date(currentYear - 1, 11, 31, 23, 59, 59),
                };
                setChosenDatePeriod === null || setChosenDatePeriod === void 0 ? void 0 : setChosenDatePeriod(updatedRange);
            }
            else {
                var updatedRange = {
                    from: new Date(currentYear + 1, 0, 1, 0, 0, 0),
                    to: new Date(currentYear + 1, 11, 31, 23, 59, 59),
                };
                setChosenDatePeriod === null || setChosenDatePeriod === void 0 ? void 0 : setChosenDatePeriod(updatedRange);
            }
        }
        if (isCustomSelected) {
            var difference = Math.floor(moment.duration(moment(to).diff(from)).asDays());
            if (actionType === rangePickerInputActionTypeEnum.PREV) {
                if (difference >= 27) {
                    var updatedRange = {
                        from: moment(from).subtract(1, 'month').toDate(),
                        to: moment(to).date() >= 28 ? moment(to).subtract(1, 'month').endOf('month').toDate() : moment(to).subtract(1, 'month').toDate(),
                    };
                    setChosenDatePeriod === null || setChosenDatePeriod === void 0 ? void 0 : setChosenDatePeriod(updatedRange);
                }
                else {
                    var updatedRange = {
                        from: moment(from).subtract(difference + 1, 'days').toDate(),
                        to: moment(to).subtract(difference + 1, 'days').toDate(),
                    };
                    setChosenDatePeriod === null || setChosenDatePeriod === void 0 ? void 0 : setChosenDatePeriod(updatedRange);
                }
            }
            else if (difference >= 27) {
                var updatedRange = {
                    from: moment(from).add(1, 'month').toDate(),
                    to: moment(to).date() >= 28 ? moment(to).add(1, 'month').endOf('month').toDate() : moment(to).add(1, 'month').toDate(),
                };
                setChosenDatePeriod === null || setChosenDatePeriod === void 0 ? void 0 : setChosenDatePeriod(updatedRange);
            }
            else {
                var updatedRange = {
                    from: moment(from).add(difference + 1, 'days').toDate(),
                    to: moment(to).add(difference + 1, 'days').toDate(),
                };
                setChosenDatePeriod === null || setChosenDatePeriod === void 0 ? void 0 : setChosenDatePeriod(updatedRange);
            }
        }
    };
    return (_jsxs("div", __assign({ className: classes.root }, { children: [_jsx(Icons.ArrowDropLeft, { className: classes.arrow, onClick: function () { return handleClick(rangePickerInputActionTypeEnum.PREV); } }, void 0), _jsx("span", __assign({ "data-testid": 'rangePicker-input', ref: ref }, props, { className: classes.selectedValue }, { children: value }), void 0), _jsx(Icons.ArrowDropRight, { className: classes.arrow, onClick: function () { return handleClick(rangePickerInputActionTypeEnum.NEXT); } }, void 0)] }), void 0));
});
export default Input;
