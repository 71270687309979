import { makeStyles } from '@material-ui/core/styles';
var warningSvgURL = 'url("data:image/svg+xml,%3Csvg width=\'24\' height=\'24\' viewBox=\'0 0 24 24\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Cpath fill-rule=\'evenodd\' clip-rule=\'evenodd\' d=\'M12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3ZM12 5C8.13401 5 5 8.13401 5 12C5 15.866 8.13401 19 12 19C15.866 19 19 15.866 19 12C19 8.13401 15.866 5 12 5ZM12 14C12.5523 14 13 14.4477 13 15C13 15.5523 12.5523 16 12 16C11.4477 16 11 15.5523 11 15C11 14.4477 11.4477 14 12 14ZM12 7C12.5523 7 13 7.44772 13 8V12C13 12.5523 12.5523 13 12 13C11.4477 13 11 12.5523 11 12V8C11 7.44772 11.4477 7 12 7Z\' fill=\'%23FF333D\'/%3E%3C/svg%3E")';
var useStyles = makeStyles(function (theme) { return ({
    input: {
        backgroundColor: theme.palette.grey[100] + " !important",
        color: theme.palette.grey[700] + " !important",
        transition: "all " + theme.transitions.easing.easeOut + " " + theme.transitions.duration.standard + "ms",
    },
    container: function (props) { return ({
        '&:hover .form-control': {
            border: !props.error ? "2px solid " + theme.palette.grey[400] + " !important" : "2px solid " + theme.palette.error.main + " !important",
            backgroundColor: 'white !important',
        },
        '&:focus-within .form-control': {
            color: theme.palette.common.black + " !important",
            border: !props.error ? "2px solid " + theme.palette.primary.main + " !important" : "2px solid " + theme.palette.error.main + " !important",
            backgroundColor: theme.palette.common.white + " !important",
        },
        '&:focus-within .special-label': {
            color: !props.error ? theme.palette.primary.main + " !important" : theme.palette.error.main + " !important",
        },
        '&:after': {
            position: 'absolute',
            display: 'block',
            width: '20px',
            height: '20px',
            content: props.error ? "" + warningSvgURL : '""',
            top: '8px',
            right: '19px',
        },
        '& .special-label': {
            transition: 'color 300ms',
            display: 'block !important',
            left: '0px !important',
            top: '-22px !important',
            background: 'transparent !important',
            fontFamily: [
                'Manrope',
                '"Segoe UI"',
                'Tahoma',
                'Geneva',
                'Verdana',
                'sans-serif',
            ].join(','),
            fontWeight: 'bold',
            lineHeight: '16px',
            color: !props.error ? theme.palette.common.black + " !important" : theme.palette.error.main + " !important",
        },
    }); },
    errorInput: {
        border: "2px solid " + theme.palette.error.main + " !important",
        backgroundColor: theme.palette.common.white + " !important",
    },
    dropdownButton: {
        '& .selected-flag': {
            paddingLeft: '18px !important',
            width: '78px !important',
        },
        '&.flag-dropdown.open .up': {
            borderBottom: "6.46px solid " + theme.palette.primary.main + " !important",
        },
        '& .arrow': {
            left: '30px !important',
            marginTop: '-3px !important',
            borderLeft: '4.83px solid transparent !important',
            borderRight: '4.83px solid transparent !important',
            borderTop: "6.46px solid " + theme.palette.grey[700] + " !important",
        },
        '& .arrow.up': {
            borderTop: 'none !important',
        },
    },
    dropdown: {
        '& .country': {
            color: theme.palette.common.black,
            fontFamily: [
                'Manrope',
                '"Segoe UI"',
                'Tahoma',
                'Geneva',
                'Verdana',
                'sans-serif',
            ].join(','),
            fontStyle: 'normal',
            fontWeight: 500,
            fontSize: '15px',
            lineHeight: '24px',
        },
        '& .flag': {
            marginRight: '8px !important',
        },
    },
    errorMessage: {
        fontSize: 13,
        fontWeight: 500,
        marginTop: '-12px',
        paddingLeft: '3px',
        color: theme.palette.error.main,
        textAlign: 'left',
    },
}); });
export default useStyles;
var containerStyle = {
    width: '100%',
    fontSize: '14px',
};
var inputStyle = {
    borderRadius: '8px',
    border: '2px solid transparent',
    width: '100%',
    minHeight: 40,
    paddingLeft: '80px',
    fontFamily: [
        'Manrope',
        '"Segoe UI"',
        'Tahoma',
        'Geneva',
        'Verdana',
        'sans-serif',
    ].join(','),
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '24px',
    paddingRight: '30px',
};
var dropdownButtonStyles = {
    top: '2px',
    bottom: '2px',
    left: '2px',
    borderRadius: '8px',
    backgroundColor: 'transparent',
    border: '2px solid transparent',
};
var dropdownStyles = {
    maxHeight: '224px',
    boxShadow: '3px 12px 32px rgba(22, 36, 61, 0.14)',
    width: '384px',
    borderRadius: '8px',
};
export var styles = {
    containerStyle: containerStyle,
    inputStyle: inputStyle,
    dropdownButtonStyles: dropdownButtonStyles,
    dropdownStyles: dropdownStyles,
};
