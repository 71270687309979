import { jsx as _jsx } from "react/jsx-runtime";
import cx from 'classnames';
import useStyles from './styles/Loading.styles';
export function Loading(_a) {
    var _b;
    var color = _a.color, hasLabel = _a.hasLabel, className = _a.className;
    var styles = useStyles();
    var classes = cx(className, styles.loader, styles[color], (_b = {},
        _b[styles.marginRight] = hasLabel,
        _b));
    return (_jsx("div", { "data-testid": 'button-loader', className: classes }, void 0));
}
