import { makeStyles } from '@material-ui/core';
var useStyles = makeStyles(function (theme) { return ({
    root: {
        fontSize: 14,
        fontWeight: 700,
        lineHeight: '24px',
        color: theme.palette.grey[500],
        background: theme.palette.grey[300],
        borderRadius: '50%',
    },
    rainbow: {
        display: 'inline-block',
        padding: '1px',
        borderRadius: '50%',
        position: 'relative',
        '&:before': {
            top: '-0.75px',
            left: '-0.75px',
            width: 'calc(100% + 1px * 2)',
            height: 'calc(100% + 1px * 2)',
            borderRadius: '50%',
            content: '""',
            zIndex: '-1',
            position: 'absolute',
            background: 'conic-gradient(rgba(60, 90, 249, 1), rgba(249, 105, 85, 1),rgba(253, 135, 170, 1), rgba(252, 213, 173, 1), rgba(233, 117, 132, 1), rgba(254, 213, 86, 1), rgba(254, 214, 85, 1), rgba(255, 244, 242, 1), rgba(242, 205, 254, 1), rgba(253, 153, 249, 1), rgba(214, 147, 250, 1), rgba(228, 146, 228, 1), rgba(30, 154, 250, 1), rgba(140, 251, 215, 1), rgba(46, 80, 246, 1))',
            backgroundSize: '100% 100%',
        },
        '& .MuiAvatar-root': {
            border: '2px solid #ffffff',
            width: 28,
            height: 28,
        },
    },
    dashed: {
        display: 'inline-block',
        border: "1px dashed " + theme.palette.grey[500],
        padding: '1px',
        borderRadius: '50%',
        '& .MuiAvatar-root': {
            width: 28,
            height: 28,
        },
    },
    default: {
        display: 'inline-block',
    },
    xxl: {
        width: 104,
        height: 104,
    },
    xl: {
        width: 80,
        height: 80,
    },
    l: {
        width: 48,
        height: 48,
    },
    m: {
        width: 32,
        height: 32,
    },
    s: {
        width: 24,
        height: 24,
        fontSize: 13,
        lineHeight: '16px',
    },
    xs: {
        width: 16,
        height: 16,
        fontSize: 13,
        lineHeight: '16px',
    },
    badge: {
        boxShadow: "0 0 0 2px " + theme.palette.background.paper,
        height: '6px',
        minWidth: '6px',
        borderRadius: '50%',
        '&::after': {
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            borderRadius: '50%',
            content: '""',
        },
    },
    badge_folder: {
        boxShadow: 'none',
        '&::after': {
            position: 'relative !important',
        },
    },
    badge_atWork: {
        backgroundColor: theme.palette.primary.main,
    },
    badge_absent: {
        backgroundColor: theme.palette.error.main,
    },
    badge_onBreak: {
        backgroundColor: '#F99C10',
    },
    badge_approved: {
        width: 16,
        boxShadow: 'none',
        '&::after': {
            content: '',
        },
        '& svg': {
            width: '16px !important',
            height: '16px !important',
        },
    },
    badge_awaitingApproval: {
        boxShadow: 'none',
        '&::after': {
            content: '',
        },
        '& svg': {
            width: '16px !important',
            height: '16px !important',
        },
    },
    folderIcon: {
        color: theme.palette.grey[500],
    },
}); });
export default useStyles;
