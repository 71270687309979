var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { makeStyles } from '@material-ui/core/styles';
import cx from 'classnames';
import { Text } from '../Typography';
export var CircleReactionEnum;
(function (CircleReactionEnum) {
    CircleReactionEnum["JobdoneAngry"] = "JOBDONE_ANGRY";
    CircleReactionEnum["JobdoneCare"] = "JOBDONE_CARE";
    CircleReactionEnum["JobdoneCelebrate"] = "JOBDONE_CELEBRATE";
    CircleReactionEnum["JobdoneCry"] = "JOBDONE_CRY";
    CircleReactionEnum["JobdoneFunny"] = "JOBDONE_FUNNY";
    CircleReactionEnum["JobdoneHaha"] = "JOBDONE_HAHA";
    CircleReactionEnum["JobdoneInsightful"] = "JOBDONE_INSIGHTFUL";
    CircleReactionEnum["JobdoneLike"] = "JOBDONE_LIKE";
    CircleReactionEnum["JobdoneLove"] = "JOBDONE_LOVE";
    CircleReactionEnum["JobdoneSupport"] = "JOBDONE_SUPPORT";
    CircleReactionEnum["JobdoneWow"] = "JOBDONE_WOW";
})(CircleReactionEnum || (CircleReactionEnum = {}));
var useStyles = makeStyles(function (theme) { return ({
    root: {
        display: 'flex',
        alignItems: 'center',
        gap: 4,
        padding: 4,
        borderRadius: 6,
        width: 'fit-content',
        backgroundColor: theme.palette.common.white,
        border: "1px solid " + theme.palette.grey[300],
        cursor: 'pointer',
        transition: '.1s',
        '&:hover': {
            backgroundColor: '#ECF6FF',
            borderColor: '#7CA6F4E5',
        },
    },
    selected: {
        backgroundColor: theme.palette.primary.main,
        borderColor: theme.palette.primary.main,
        color: theme.palette.common.white,
        '&:hover': {
            backgroundColor: theme.palette.primary.main,
            borderColor: theme.palette.primary.main,
        },
    },
    emoji: {
        width: 16,
        height: 16,
    },
    count: {
        fontWeight: 700,
        fontSize: 13,
        lineHeight: '16px',
    },
}); });
var Reaction = function (_a) {
    var _b;
    var style = _a.style, className = _a.className, type = _a.type, count = _a.count, isSelected = _a.isSelected, onClick = _a.onClick, rest = __rest(_a, ["style", "className", "type", "count", "isSelected", "onClick"]);
    var classes = useStyles();
    var getEmoji = function () {
        if (type === CircleReactionEnum.JobdoneLike)
            return 'like';
        if (type === CircleReactionEnum.JobdoneCelebrate)
            return 'celebrate';
        if (type === CircleReactionEnum.JobdoneSupport)
            return 'support';
        if (type === CircleReactionEnum.JobdoneLove)
            return 'love';
        if (type === CircleReactionEnum.JobdoneInsightful)
            return 'insightful';
        if (type === CircleReactionEnum.JobdoneFunny)
            return 'funny';
        return '';
    };
    return (_jsxs("div", __assign({ className: cx(className, classes.root, (_b = {},
            _b[classes.selected] = isSelected,
            _b)), style: style, role: 'button', "aria-hidden": true, onClick: onClick }, rest, { children: [_jsx("img", { src: "/static/emojis/" + getEmoji() + ".png", alt: getEmoji(), className: classes.emoji }, void 0), _jsx(Text, __assign({ className: classes.count }, { children: count }), void 0)] }), void 0));
};
export default Reaction;
