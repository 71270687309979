var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import useStyles from './styles';
var Trash = function (props) { return (_jsx("span", __assign({ className: useStyles().jobDoneIcon }, props, { children: _jsx("svg", __assign({ width: '24', height: '24', viewBox: '0 0 24 24', fill: 'none', xmlns: 'http://www.w3.org/2000/svg' }, { children: _jsx("path", { fillRule: 'evenodd', clipRule: 'evenodd', d: 'M10 2C9.44772 2 9 2.44772 9 3V4H4C3.44772 4 3 4.44772 3 5C3 5.55228 3.44772 6 4 6H20C20.5523 6 21 5.55228 21 5C21 4.44772 20.5523 4 20 4H15V3C15 2.44772 14.5523 2 14 2H10ZM5.00009 8.37092C5.00003 8.36643 5 8.36192 5 8.35742C5 7.80513 5.44772 7.35742 6 7.35742L17.9984 7.35742C18.5347 7.35742 18.9725 7.77968 18.9973 8.3099C18.9991 8.3345 19 8.35936 19 8.38443V19C19 20.6569 17.6569 22 16 22H8C6.34315 22 5 20.6569 5 19V8.38443C5 8.37992 5.00003 8.37542 5.00009 8.37092ZM7 9.35742V19C7 19.5523 7.44772 20 8 20H16C16.5523 20 17 19.5523 17 19V9.35742L7 9.35742ZM9 12.0563C9 11.504 9.44772 11.0563 10 11.0563C10.5523 11.0563 11 11.504 11 12.0563V17.0117C11 17.564 10.5523 18.0117 10 18.0117C9.44772 18.0117 9 17.564 9 17.0117V12.0563ZM13 12.0563C13 11.504 13.4477 11.0563 14 11.0563C14.5523 11.0563 15 11.504 15 12.0563V17.0117C15 17.564 14.5523 18.0117 14 18.0117C13.4477 18.0117 13 17.564 13 17.0117V12.0563Z', fill: 'currentColor' }, void 0) }), void 0) }), void 0)); };
export default Trash;
