export var TableTagActionTypeEnum;
(function (TableTagActionTypeEnum) {
    TableTagActionTypeEnum["REMOVE"] = "REMOVE";
    TableTagActionTypeEnum["ADD"] = "ADD";
})(TableTagActionTypeEnum || (TableTagActionTypeEnum = {}));
export var MenuItemsTypeEnum;
(function (MenuItemsTypeEnum) {
    MenuItemsTypeEnum["REGULAR"] = "REGULAR";
    MenuItemsTypeEnum["ERROR"] = "ERROR";
})(MenuItemsTypeEnum || (MenuItemsTypeEnum = {}));
