import { makeStyles } from '@material-ui/core/styles';
var useStyles = makeStyles(function (theme) { return ({
    paper: {
        background: theme.palette.common.white,
        boxShadow: '3px 12px 32px rgba(22, 36, 61, 0.14)',
        borderRadius: 8,
    },
    optionWithIcon: {
        display: 'flex',
        alignItems: 'center',
        gap: 8,
        width: '100%',
    },
    optionWithIconTextContainer: {
        display: 'flex',
        marginLeft: 10,
        flexDirection: 'column',
    },
    optionWithIconSecondaryText: {
        color: theme.palette.grey[500] + " !important",
    },
    inputStartAdornment: {
        marginLeft: 16,
    },
    optionWithIconFlexDiv: {
        display: 'flex',
        alignItems: 'center',
    },
    actionOption: {
        color: theme.palette.primary.main,
    },
}); });
export default useStyles;
