import { makeStyles } from '@material-ui/core';
var useStyles = makeStyles({
    loader: {
        borderRadius: '50%',
        minWidth: '20px',
        minHeight: '20px',
        position: 'relative',
        textIndent: '-9999em',
        '-webkit-transform': 'translateZ(0)',
        '-ms-transform': 'translateZ(0)',
        transform: 'translateZ(0)',
        '-webkit-animation': '$load8 1.1s infinite linear',
        animation: '$load8 1.1s infinite linear',
        '&:after': {
            borderRadius: '50%',
            minWidth: '20px',
            minHeight: '20px',
        },
    },
    marginRight: {
        marginRight: '12px',
    },
    primary: {
        borderTop: '4px solid rgba(32, 106, 233, 0.45)',
        borderRight: '4px solid rgba(32, 106, 233, 0.45)',
        borderBottom: '4px solid rgba(32, 106, 233, 0.45)',
        borderLeft: '4px solid #ffffff',
    },
    secondary: {
        borderTop: '4px solid #dadde4',
        borderRight: '4px solid #dadde4',
        borderBottom: '4px solid #dadde4',
        borderLeft: '4px solid #206ae9',
    },
    ghost: {
        borderTop: '4px solid #dadde4',
        borderRight: '4px solid #dadde4',
        borderBottom: '4px solid #dadde4',
        borderLeft: '4px solid #206ae9',
    },
    circleBar: {
        borderLeft: '4px solid #ffffff',
        borderTop: '4px solid transparent',
        borderRight: '4px solid transparent',
        borderBottom: '4px solid transparent',
    },
    '@keyframes load8': {
        from: {
            '-webkit-transform': 'rotate(0deg)',
            transform: 'rotate(0deg)',
        },
        to: {
            '-webkit-transform': 'rotate(360deg)',
            transform: 'rotate(360deg)',
        },
    },
});
export default useStyles;
