var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable react/no-danger */
import { makeStyles } from '@material-ui/core/styles';
import cx from 'classnames';
import { ShevronRight } from '../icons';
var useStyles = makeStyles(function (theme) { return ({
    container: {
        padding: '8px 16px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        borderRadius: 8,
        cursor: 'pointer',
        transition: theme.transitions.create(['background', 'color']),
        '&:hover': {
            backgroundColor: theme.palette.secondary.light,
        },
    },
    label: {
        fontFamily: theme.typography.fontFamily,
        fontWeight: 500,
        fontSize: 14,
        lineHeight: '24px',
        color: theme.palette.grey[700],
        '&>b': {
            color: theme.palette.common.black,
        },
    },
    icon: {
        color: theme.palette.grey[500],
    },
}); });
var ActionCard = function (_a) {
    var label = _a.label, style = _a.style, className = _a.className, id = _a.id, onClick = _a.onClick, rest = __rest(_a, ["label", "style", "className", "id", "onClick"]);
    var classes = useStyles();
    return (_jsxs("div", __assign({ style: style, id: id, className: cx(classes.container, className), onClick: onClick, role: 'button', "aria-hidden": true }, rest, { children: [_jsx("span", { className: classes.label, dangerouslySetInnerHTML: { __html: label } }, void 0), _jsx(ShevronRight, { className: classes.icon }, void 0)] }), void 0));
};
export default ActionCard;
