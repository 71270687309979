export var Sizes = {
    small: 'small',
    medium: 'medium',
    large: 'large',
};
export var Variants = {
    fixedIcon: 'fixedIcon',
    fixedLabel: 'fixedLabel',
    autoWidth: 'autoWidth',
};
export var Colors = {
    primary: 'primary',
    secondary: 'secondary',
    ghost: 'ghost',
};
