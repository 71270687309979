var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useCallback, useRef, useState } from 'react';
import { GoogleMap, useLoadScript, Marker, } from '@react-google-maps/api';
import { makeStyles } from '@material-ui/core';
import cx from 'classnames';
import { AutoComplete } from './AutoComplete';
import { Skeleton } from '../../Skeleton';
import { center, libraries } from './common';
var useStyles = makeStyles(function () { return ({
    mapContainerClassName: {
        width: '100%',
        height: '500px',
        borderRadius: 8,
    },
}); });
var AddressAutoComplete = function (_a) {
    var getAddressDetails = _a.getAddressDetails, locale = _a.locale, hiddenLabel = _a.hiddenLabel, defaultValue = _a.defaultValue, centerCoordinates = _a.centerCoordinates, hasError = _a.hasError, disabled = _a.disabled, debounce = _a.debounce, mapContainerClassName = _a.mapContainerClassName;
    var classes = useStyles();
    var _b = useLoadScript({
        googleMapsApiKey: 'AIzaSyAM3YeqvmIWQBkJMLzlsyRuMt7d58fA7xc',
        libraries: libraries,
    }), isLoaded = _b.isLoaded, loadError = _b.loadError;
    var _c = __read(useState(null), 2), selectedPlace = _c[0], setSelectedPlace = _c[1];
    var mapRef = useRef();
    var onMapLoad = useCallback(function (map) {
        mapRef.current = map;
    }, []);
    var setAddressDetails = function (addressDetails) {
        var _a, _b;
        setSelectedPlace(addressDetails);
        (_a = mapRef === null || mapRef === void 0 ? void 0 : mapRef.current) === null || _a === void 0 ? void 0 : _a.panTo({ lat: addressDetails.latitude, lng: addressDetails.longitude });
        (_b = mapRef === null || mapRef === void 0 ? void 0 : mapRef.current) === null || _b === void 0 ? void 0 : _b.setZoom(14);
        getAddressDetails === null || getAddressDetails === void 0 ? void 0 : getAddressDetails(addressDetails);
    };
    if (!isLoaded)
        return _jsx(Skeleton, { variant: 'rect', className: cx(classes.mapContainerClassName, mapContainerClassName) }, void 0);
    return !loadError ? (_jsxs("div", { children: [_jsx(AutoComplete, { getAddressDetails: setAddressDetails, center: centerCoordinates || center, locale: locale, hiddenLabel: hiddenLabel, defaultValue: defaultValue, hasError: hasError, disabled: disabled, debounce: debounce }, void 0), _jsx(GoogleMap, __assign({ id: 'map', mapContainerClassName: cx(classes.mapContainerClassName, mapContainerClassName), zoom: 8, center: centerCoordinates || center, onLoad: onMapLoad }, { children: selectedPlace && (_jsx(Marker, { position: { lat: selectedPlace.latitude, lng: selectedPlace.longitude } }, String(selectedPlace.latitude) + "-" + String(selectedPlace.longitude))) }), void 0)] }, void 0)) : _jsx(_Fragment, {}, void 0);
};
export default AddressAutoComplete;
