var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Tooltip as MUITooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
var useStyles = makeStyles(function () { return ({
    default: {
        backgroundColor: '#16243D',
        color: '#fff',
        padding: '6px 8px',
        fontWeight: 500,
        fontSize: '13px',
        lineHeight: '16px',
        borderRadius: '8px',
        margin: '8px',
    },
    custom: {
        backgroundColor: 'transparent',
    },
}); });
export var Tooltip = function (_a) {
    var children = _a.children, className = _a.className, title = _a.title, isCustom = _a.isCustom, rest = __rest(_a, ["children", "className", "title", "isCustom"]);
    var styles = useStyles();
    var classes = isCustom ? styles.custom : styles.default;
    return (_jsx(MUITooltip, __assign({ title: title, className: className, enterDelay: 0, classes: {
            tooltip: classes,
        } }, rest, { children: children }), void 0));
};
