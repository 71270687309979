var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import useStyles from './styles';
var ErrorWithExclamation = function (props) { return (_jsx("span", __assign({ className: useStyles().jobDoneIcon }, props, { children: _jsx("svg", __assign({ width: '18', height: '18', viewBox: '0 0 18 18', fill: 'none', xmlns: 'http://www.w3.org/2000/svg' }, { children: _jsx("path", { fillRule: 'evenodd', clipRule: 'evenodd', d: 'M9 0C13.9706 0 18 4.02944 18 9C18 13.9706 13.9706 18 9 18C4.02944 18 0 13.9706 0 9C0 4.02944 4.02944 0 9 0ZM9 2C5.13401 2 2 5.13401 2 9C2 12.866 5.13401 16 9 16C12.866 16 16 12.866 16 9C16 5.13401 12.866 2 9 2ZM9 11C9.55229 11 10 11.4477 10 12C10 12.5523 9.55229 13 9 13C8.44771 13 8 12.5523 8 12C8 11.4477 8.44771 11 9 11ZM9 4C9.55229 4 10 4.44772 10 5V9C10 9.55229 9.55229 10 9 10C8.44771 10 8 9.55229 8 9V5C8 4.44772 8.44771 4 9 4Z', fill: '#FF333D' }, void 0) }), void 0) }), void 0)); };
export default ErrorWithExclamation;
