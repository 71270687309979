import { makeStyles } from '@material-ui/core';
var useStyles = makeStyles(function (theme) { return ({
    tableContainer: {
        width: '100%',
    },
    table: {
        display: 'table',
        width: '100%',
        whiteSpace: 'nowrap',
        overflowX: 'auto',
        borderSpacing: 0,
        '& th:first-child': {
            borderRadius: '8px 0 0 8px',
        },
        '& th:last-child': {
            borderRadius: '0 8px 8px 0',
        },
        borderCollapse: 'collapse',
    },
    horizontalScroll: {
        display: 'block !important',
    },
    tableWithBorderRadius: {
        borderRadius: 8,
    },
    head: {
        backgroundColor: theme.palette.grey[100],
        textAlign: 'left',
    },
    headCell: {
        paddingLeft: 16,
        paddingRight: 16,
        padding: '12px 0',
        color: theme.palette.common.black,
        transition: theme.transitions.create('all'),
        '&:hover': {
            backgroundColor: theme.palette.secondary.light,
        },
    },
    space: {
        display: 'block',
        marginTop: 16,
    },
    row: {
        cursor: 'pointer',
        borderBottom: "1px solid " + theme.palette.grey[300],
        transition: theme.transitions.create('all'),
        '&:hover': {
            backgroundColor: theme.palette.secondary.light,
            borderBottom: "1px solid " + theme.palette.primary.main,
        },
    },
    cell: {
        paddingLeft: 16,
        paddingRight: 16,
        height: 63,
    },
    minRowHeight: {
        height: '25px !important',
        paddingLeft: '8px !important',
        paddingRight: '8px !important',
    },
    timeTrackingAndReport: {
        height: '54px !important',
    },
    timeTrackingAndReportDateRow: {
        height: '28px !important',
    },
    totalRow: {
        backgroundColor: '#ECF6FF',
        borderBottom: 'unset',
        cursor: 'default',
        marginTop: 20,
        fontWeight: 700,
        '&:hover': {
            backgroundColor: '#ECF6FF',
            borderBottom: 'unset',
        },
    },
    endBalanceColumn: {
        fontWeight: 700,
        backgroundColor: '#ECF6FF',
    },
    noPaddingColumn: {
        padding: 0,
        textAlign: 'center',
    },
    darkerBlue: {
        backgroundColor: '#DAECFF',
    },
    grandTotalEndBalance: {
        backgroundColor: '#D7F6ED !important',
    },
    dateRow: {
        '&:hover': {
            backgroundColor: theme.palette.grey[100],
        },
        cursor: 'default',
        borderBottom: 'unset !important',
        '& > td': {
            backgroundColor: theme.palette.grey[100],
            height: 32,
        },
    },
    noBorderBottom: {
        borderBottom: 'unset !important',
    },
    rowLeftSide: {
        borderTopLeftRadius: 8,
        borderBottomLeftRadius: 8,
    },
    rowRightSide: {
        borderTopRightRadius: 8,
        borderBottomRightRadius: 8,
    },
    isEmptyDate: {
        cursor: 'default',
        backgroundColor: theme.palette.common.white,
        '&:hover': {
            backgroundColor: theme.palette.common.white,
        },
    },
    employeeRow: {
        cursor: 'default',
        '& > td': {
            backgroundColor: theme.palette.grey[100],
            height: 60,
        },
    },
}); });
export default useStyles;
