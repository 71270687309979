var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import cx from 'classnames';
import useStyles from './styles/Typography.styles';
var Weights = {
    100: 100,
    200: 200,
    300: 300,
    400: 400,
    500: 500,
    600: 600,
    700: 700,
    800: 800,
    900: 900,
};
var Displays = {
    none: 'none',
    block: 'block',
    inline: 'inline',
    inlineBlock: 'inline-block',
    inherit: 'inherit',
};
export var Text = function (_a) {
    var children = _a.children, _b = _a.className, className = _b === void 0 ? '' : _b, size = _a.size, weight = _a.weight, component = _a.component, display = _a.display, _c = _a.style, style = _c === void 0 ? {} : _c, _d = _a.testId, testId = _d === void 0 ? '' : _d, props = __rest(_a, ["children", "className", "size", "weight", "component", "display", "style", "testId"]);
    var styles = useStyles();
    var Component = component || 'span';
    var classNames = cx(styles.root, className);
    var componentStyle = __assign({}, style);
    if (size)
        componentStyle.fontSize = size;
    if (display)
        componentStyle.display = display;
    if (weight)
        componentStyle.fontWeight = weight;
    return (_jsx(Component, __assign({}, props, { className: classNames, style: componentStyle, "data-testid": testId }, { children: children }), void 0));
};
