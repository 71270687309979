var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { makeStyles } from '@material-ui/core/styles';
import cx from 'classnames';
import { Icons } from '../..';
var useStyles = makeStyles(function () { return ({
    root: function (_a) {
        var withDropdownIcon = _a.withDropdownIcon;
        return ({
            userSelect: 'none',
            padding: '4px 8px',
            borderRadius: 6,
            fontFamily: 'Manrope',
            fontStyle: 'normal',
            fontWeight: 'bold',
            fontSize: 13,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            cursor: withDropdownIcon ? 'pointer' : 'default',
        });
    },
    dropdownIcon: {
        '& > svg': {
            width: 14,
            height: 14,
            verticalAlign: 'middle',
        },
    },
}); });
export var Tag = function (_a) {
    var _b = _a.children, children = _b === void 0 ? 'Business' : _b, _c = _a.color, color = _c === void 0 ? '#028CA2' : _c, _d = _a.backgroundColor, backgroundColor = _d === void 0 ? '#E0FCFF' : _d, className = _a.className, _e = _a.withDropdownIcon, withDropdownIcon = _e === void 0 ? false : _e, props = __rest(_a, ["children", "color", "backgroundColor", "className", "withDropdownIcon"]);
    var classes = useStyles({ withDropdownIcon: withDropdownIcon });
    return (_jsxs("span", __assign({}, props, { className: cx(classes.root, className), style: __assign(__assign({}, props.style), { color: color, backgroundColor: backgroundColor }) }, { children: [children, withDropdownIcon && _jsx(Icons.DropdownIcon, { className: classes.dropdownIcon }, void 0)] }), void 0));
};
