var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable react/no-danger */
import { makeStyles } from '@material-ui/core';
import cx from 'classnames';
import { Avatar } from '../Avatar';
import { Body } from '../Typography';
var useStyles = makeStyles(function (theme) { return ({
    container: {
        padding: 16,
        backgroundColor: theme.palette.grey[100],
        borderRadius: 16,
        display: 'flex',
        gap: 16,
    },
    description: {
        display: 'block',
        marginTop: 4,
        fontFamily: theme.typography.fontFamily,
        fontWeight: 500,
        fontSize: 14,
        lineHeight: '24px',
        color: theme.palette.grey[700],
        '&>b': {
            color: theme.palette.common.black,
        },
    },
}); });
var Birthday = function (_a) {
    var style = _a.style, className = _a.className, title = _a.title, description = _a.description, src = _a.src, rest = __rest(_a, ["style", "className", "title", "description", "src"]);
    var classes = useStyles();
    return (_jsxs("div", __assign({ style: style, className: cx(classes.container, className) }, rest, { children: [_jsx(Avatar, { size: 'l', src: src, placeHolder: title }, void 0), _jsxs("div", { children: [_jsx(Body, __assign({ variant: 'medium-700' }, { children: title }), void 0), _jsx("span", { className: classes.description, dangerouslySetInnerHTML: { __html: description } }, void 0)] }, void 0)] }), void 0));
};
export default Birthday;
