import { makeStyles } from '@material-ui/core';
var useStyles = makeStyles(function (theme) { return ({
    label: {
        color: theme.palette.common.black,
        fontWeight: 700,
        marginBottom: '0.8rem',
        fontSize: 13,
        textAlign: 'left',
        display: 'flex',
        width: '100%',
        alignItems: 'flex-start',
    },
    labelActive: {
        color: theme.palette.primary.main + " !important",
    },
    labelSuccess: {
        color: theme.palette.success.main,
    },
    labelError: {
        color: theme.palette.error.main + " !important",
    },
    dot: {
        fontSize: 22,
        lineHeight: 0,
        color: theme.palette.error.main,
    },
    errorMessage: {
        fontSize: 13,
        fontWeight: 500,
        marginTop: 6,
        color: theme.palette.error.main,
        textAlign: 'left',
    },
}); });
export default useStyles;
