var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import cx from 'classnames';
import useStyles from './styles';
var TemplatesIcon = function (props) {
    var className = props.className, rest = __rest(props, ["className"]);
    return (_jsx("span", __assign({ className: cx(useStyles().jobDoneIcon, className) }, rest, { children: _jsxs("svg", __assign({ width: '24', height: '24', viewBox: '0 0 24 24', fill: 'none', xmlns: 'http://www.w3.org/2000/svg' }, { children: [_jsx("path", { fillRule: 'evenodd', clipRule: 'evenodd', d: 'M12 2C12.5523 2 13 2.44772 13 3V21C13 21.5523 12.5523 22 12 22C11.4477 22 11 21.5523 11 21V3C11 2.44772 11.4477 2 12 2Z', fill: 'currentColor' }, void 0), _jsx("path", { fillRule: 'evenodd', clipRule: 'evenodd', d: 'M11 10.2C11 9.64767 11.4477 9.19995 12 9.19995H21C21.5523 9.19995 22 9.64767 22 10.2C22 10.7522 21.5523 11.2 21 11.2H12C11.4477 11.2 11 10.7522 11 10.2Z', fill: 'currentColor' }, void 0), _jsx("path", { fillRule: 'evenodd', clipRule: 'evenodd', d: 'M2 14.7C2 14.1477 2.44772 13.7 3 13.7H12C12.5523 13.7 13 14.1477 13 14.7C13 15.2522 12.5523 15.7 12 15.7H3C2.44772 15.7 2 15.2522 2 14.7Z', fill: 'currentColor' }, void 0), _jsx("path", { fillRule: 'evenodd', clipRule: 'evenodd', d: 'M5 4C4.45228 4 4 4.45228 4 5V19C4 19.5477 4.45228 20 5 20H19C19.5477 20 20 19.5477 20 19V5C20 4.45228 19.5477 4 19 4H5ZM2 5C2 3.34772 3.34772 2 5 2H19C20.6523 2 22 3.34772 22 5V19C22 20.6523 20.6523 22 19 22H5C3.34772 22 2 20.6523 2 19V5Z', fill: 'currentColor' }, void 0)] }), void 0) }), void 0));
};
export default TemplatesIcon;
