var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { makeStyles } from '@material-ui/core/styles';
import cx from 'classnames';
import { Body, Caption } from '../Typography';
var useStyles = makeStyles(function (theme) { return ({
    root: {
        display: 'flex',
    },
    senderRoot: {
        flexDirection: 'row-reverse',
    },
    container: {
        padding: '8px 12px',
        maxWidth: 440,
        wordBreak: 'break-word',
        whiteSpace: 'pre-wrap',
        backgroundColor: '#ECF6FF',
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        overflow: 'hidden',
    },
    sender: {
        borderRadius: '16px 16px 0px 16px',
    },
    others: {
        borderRadius: '16px 16px 16px 0px',
    },
    username: {
        color: theme.palette.primary.main,
        fontSize: '13px',
        lineHeight: '20px',
        fontFamily: theme.typography.fontFamily,
    },
    message: {
        fontWeight: 500,
        fontSize: '13px',
        lineHeight: '20px',
        fontFamily: theme.typography.fontFamily,
        color: theme.palette.grey[700],
    },
    line: {
        width: 4,
        height: '100%',
        backgroundColor: '#7CA6F4E5',
        position: 'absolute',
        left: 0,
        bottom: 0,
    },
}); });
var ReplyBubble = function (_a) {
    var _b, _c;
    var children = _a.children, style = _a.style, className = _a.className, type = _a.type, userName = _a.userName, rest = __rest(_a, ["children", "style", "className", "type", "userName"]);
    var classes = useStyles();
    return (_jsx("div", __assign({ className: cx(className, classes.root, (_b = {},
            _b[classes.senderRoot] = type === 'sender',
            _b)), style: style }, rest, { children: _jsxs("div", __assign({ className: cx(classes.container, (_c = {},
                _c[classes.sender] = type === 'sender',
                _c[classes.others] = type === 'others',
                _c)) }, { children: [_jsx("div", { className: classes.line }, void 0), _jsx(Caption, __assign({ weight: 700, className: classes.username }, { children: userName }), void 0), _jsx(Body, __assign({ variant: 'small', className: classes.message }, { children: children }), void 0)] }), void 0) }), void 0));
};
export default ReplyBubble;
