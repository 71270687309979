var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import useStyles from './styles';
var GrayEye = function (props) { return (_jsx("span", __assign({ className: useStyles().jobDoneIcon }, props, { children: _jsx("svg", __assign({ width: '18', height: '12', viewBox: '0 0 18 12', fill: 'none', xmlns: 'http://www.w3.org/2000/svg' }, { children: _jsx("path", { fillRule: 'evenodd', clipRule: 'evenodd', d: 'M9 0C12.1854 0 15.054 1.62237 17.5689 4.7738C18.1088 5.45029 18.1425 6.37964 17.6647 7.09444L17.5624 7.2344L17.3133 7.53977C14.8609 10.4849 12.0772 12 9 12C5.81457 12 2.94603 10.3776 0.431068 7.2262C-0.108789 6.54971 -0.142529 5.62036 0.335256 4.90556L0.437635 4.7656L0.686655 4.46023C3.13906 1.51505 5.92285 0 9 0ZM9 2C6.69098 2 4.54441 3.11992 2.53239 5.42809L2.29159 5.71078L2.23765 5.79103C2.15518 5.94769 2.17164 6.1421 2.28703 6.2837C4.37341 8.78912 6.6001 10 9 10C11.309 10 13.4556 8.88008 15.4676 6.57191L15.7084 6.28922L15.7624 6.20897C15.8448 6.05231 15.8284 5.8579 15.713 5.7163C13.6266 3.21088 11.3999 2 9 2ZM9 3C10.6569 3 12 4.34315 12 6C12 7.65685 10.6569 9 9 9C7.34315 9 6 7.65685 6 6C6 4.34315 7.34315 3 9 3ZM9 5C8.44771 5 8 5.44772 8 6C8 6.55228 8.44771 7 9 7C9.55228 7 10 6.55228 10 6C10 5.44772 9.55228 5 9 5Z', fill: 'currentColor' }, void 0) }), void 0) }), void 0)); };
export default GrayEye;
