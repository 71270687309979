import { makeStyles } from '@material-ui/core/styles';
var useStyles = makeStyles(function (theme) { return ({
    root: {
        fontFamily: theme.typography.fontFamily,
        display: 'inline-block',
        background: theme.palette.grey[100],
        color: theme.palette.primary.main,
        lineHeight: '24px',
        fontWeight: 'bold',
        cursor: 'pointer',
        transition: '300ms ease-out',
        textAlign: 'center',
        '&:hover': {
            background: '#ECF6FF',
        },
    },
    Large: {
        borderRadius: '12px',
        padding: '12px 24px',
        fontSize: '16px',
    },
    Medium: {
        borderRadius: '8px',
        padding: '8px 16px',
        fontSize: '15px',
    },
    selected: {
        background: theme.palette.primary.main,
        color: theme.palette.common.white,
        '&:hover': {
            background: theme.palette.primary.main,
            color: theme.palette.common.white,
        },
    },
    disabled: {
        background: theme.palette.grey[100],
        color: theme.palette.grey[400],
        cursor: 'not-allowed',
        '&:hover': {
            background: theme.palette.grey[100],
            color: theme.palette.grey[400],
        },
    },
}); });
export default useStyles;
