import { makeStyles } from '@material-ui/core/styles';
var $height = 56;
var useStyles = makeStyles(function (theme) { return ({
    root: {
        background: theme.palette.common.white,
        boxShadow: '0px 5px 5px rgba(22, 36, 61, 0.06)',
        height: $height,
        color: theme.palette.grey[500],
    },
    toolbar: {
        minHeight: $height,
        maxHeight: $height,
        paddingLeft: '8px',
        paddingRight: '32px',
    },
    logoBlock: {
        background: theme.palette.primary.main,
        borderRadius: '8px',
        height: 40,
        width: 40,
        marginRight: '20px',
        '&:hover': {
            background: theme.palette.primary.main,
        },
    },
}); });
export default useStyles;
