var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { makeStyles } from '@material-ui/core';
import { useState } from 'react';
import cx from 'classnames';
import { sortingOrderEnum } from '@jobdone/jobdone-shared-library';
import { Body } from '../../Typography';
import { Icons } from '../../..';
var useStyles = makeStyles(function (theme) { return ({
    sortIcon: {
        verticalAlign: 'bottom',
        marginLeft: 6,
        color: theme.palette.grey[500],
        cursor: 'pointer',
        '& > svg': {
            width: 17,
        },
    },
    sortingBtn: {
        border: 'none',
        backgroundColor: 'unset',
        cursor: 'pointer',
        '&:hover > *': {
            color: theme.palette.primary.main,
        },
        '&:hover $noSort': {
            opacity: 1,
        },
    },
    activeColumn: {
        '& $sortIcon': {
            color: theme.palette.primary.dark,
        },
    },
    noSort: {
        transition: theme.transitions.create('all'),
        opacity: 0,
    },
}); });
export var SortableColumn = function (_a) {
    var _b;
    var _c = _a.title, title = _c === void 0 ? 'Column 1' : _c, getSortType = _a.getSortType, _d = _a.testId, testId = _d === void 0 ? 'table-column-sort-btn' : _d;
    var classes = useStyles();
    var _e = __read(useState(sortingOrderEnum.ASC), 2), sortType = _e[0], setSortType = _e[1];
    var handleClick = function () {
        switch (sortType) {
            case sortingOrderEnum.DESC:
                setSortType('');
                getSortType('');
                break;
            case sortingOrderEnum.ASC:
                setSortType(sortingOrderEnum.DESC);
                getSortType(sortingOrderEnum.DESC);
                break;
            default:
                setSortType(sortingOrderEnum.ASC);
                getSortType(sortingOrderEnum.ASC);
                break;
        }
    };
    var renderSortingIcon = function () {
        if (sortType === sortingOrderEnum.ASC) {
            return _jsx(Icons.SortAmountDown, { "data-testid": 'table-sort-a-z', className: classes.sortIcon }, void 0);
        }
        if (sortType === sortingOrderEnum.DESC) {
            return _jsx(Icons.SortAmounUp, { "data-testid": 'table-sort-z-a', className: classes.sortIcon }, void 0);
        }
        return _jsx(Icons.SortAmountDown, { "data-testid": 'table-sort-no-sort', className: cx(classes.sortIcon, classes.noSort) }, void 0);
    };
    return (_jsxs("button", __assign({ onClick: handleClick, "data-testid": testId, className: cx(classes.sortingBtn, (_b = {}, _b[classes.activeColumn] = sortType !== null, _b)) }, { children: [_jsx(Body, __assign({ component: 'span', variant: 'medium-700' }, { children: title }), void 0), renderSortingIcon()] }), void 0));
};
