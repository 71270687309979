var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import cx from 'classnames';
import { makeStyles } from '@material-ui/core';
import { useDropdownMenu } from '../../utils';
import { Button } from '../Button';
import { DropdownMenu } from '../DropdownMenu';
import { MoreHorizontal } from '../icons';
import { Body } from '../Typography';
var useStyles = makeStyles(function (theme) { return ({
    root: {
        width: '100%',
        borderRadius: 16,
        backgroundColor: theme.palette.grey[100],
        padding: 16,
    },
    header: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    title: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        gap: 8,
    },
    headerDetails: {
        display: 'flex',
        justifyContent: 'space-between',
        gap: 8,
    },
    timeContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        gap: 8,
    },
    time: {
        color: theme.palette.grey[700],
    },
    isRead: {
        width: 8,
        height: 8,
        borderRadius: '50%',
        backgroundColor: theme.palette.error.main,
    },
    content: {
        marginTop: 14,
        marginBottom: 10,
    },
    moreIcon: {
        color: theme.palette.grey[500],
    },
    horizontalLine: {
        width: '100%',
        height: 1,
        background: theme.palette.grey[300],
        margin: '8px 0px',
    },
    dropdownDeleteItem: {
        color: theme.palette.error.main,
        '&:hover': {
            backgroundColor: theme.palette.error.light,
            color: theme.palette.error.main,
        },
        '&:active': {
            backgroundColor: '#FFEAE8',
            color: theme.palette.error.main,
        },
    },
    disabledMenuItem: {
        color: theme.palette.grey[500],
        cursor: 'not-allowed',
        '&:hover': {
            backgroundColor: 'transparent',
            color: theme.palette.grey[500],
        },
    },
}); });
export var Informational = function (_a) {
    var _b;
    var id = _a.id, locale = _a.locale, icon = _a.icon, isRead = _a.isRead, isLoading = _a.isLoading, handleMarkAsRead = _a.handleMarkAsRead, handleDeleteNotification = _a.handleDeleteNotification;
    var classes = useStyles();
    var _c = useDropdownMenu(), anchorEl = _c.anchorEl, handleClick = _c.handleClick, handleClose = _c.handleClose;
    return (_jsxs("div", __assign({ className: classes.root, id: id }, { children: [_jsxs("div", __assign({ className: classes.header }, { children: [_jsxs("div", __assign({ className: classes.title }, { children: [icon, _jsx(Body, __assign({ variant: 'medium-700' }, { children: locale.title }), void 0)] }), void 0), _jsxs("div", __assign({ className: classes.headerDetails }, { children: [_jsxs("div", __assign({ className: classes.timeContainer }, { children: [_jsx(Body, __assign({ className: classes.time }, { children: locale.time }), void 0), !isRead && _jsx("div", { className: classes.isRead }, void 0)] }), void 0), _jsxs(_Fragment, { children: [_jsx(Button, { color: 'ghost', variant: 'fixedIcon', icon: _jsx(MoreHorizontal, { className: classes.moreIcon }, void 0), onClick: handleClick, disabled: isLoading }, void 0), _jsxs(DropdownMenu, __assign({ anchorEl: anchorEl, open: Boolean(anchorEl), handleClose: handleClose }, { children: [_jsx(DropdownMenu.Item, __assign({ onClick: function () { return !isRead && (handleMarkAsRead === null || handleMarkAsRead === void 0 ? void 0 : handleMarkAsRead(id)); }, className: cx((_b = {}, _b[classes.disabledMenuItem] = isRead, _b)) }, { children: locale.markAsRead }), void 0), _jsx("div", { className: classes.horizontalLine }, void 0), _jsx(DropdownMenu.Item, __assign({ className: classes.dropdownDeleteItem, onClick: function () { return handleDeleteNotification === null || handleDeleteNotification === void 0 ? void 0 : handleDeleteNotification(id); } }, { children: locale.deleteNotification }), void 0)] }), void 0)] }, void 0)] }), void 0)] }), void 0), locale.body && (_jsx("div", __assign({ className: classes.content }, { children: _jsx("span", { dangerouslySetInnerHTML: { __html: locale.body } }, void 0) }), void 0))] }), void 0));
};
