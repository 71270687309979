var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import cx from 'classnames';
import useStyles from './styles';
var Break = function (props) {
    var className = props.className, rest = __rest(props, ["className"]);
    return (_jsx("span", __assign({ className: cx(useStyles().jobDoneIcon, className) }, rest, { children: _jsxs("svg", __assign({ width: '24', height: '24', viewBox: '0 0 24 24', fill: 'none', xmlns: 'http://www.w3.org/2000/svg' }, { children: [_jsx("path", { d: 'M15.0965 2H8.90418C5.09723 2 2 5.09685 2 8.90346V15.0965C2 18.9031 5.09723 22 8.90418 22H15.0965C18.9031 22 22 18.9031 22 15.0965V8.90346C22 5.0969 18.9031 2 15.0965 2Z', fill: 'currentColor' }, void 0), _jsx("path", { d: 'M8.97891 9.43359C9.00937 9.61406 9.16641 9.75 9.35391 9.75H9.74063C9.97031 9.75 10.1531 9.55078 10.1227 9.32812C10.0336 8.66719 9.73828 8.05781 9.26484 7.57734C8.92734 7.23281 8.71172 6.79688 8.64609 6.32344C8.62031 6.13828 8.46094 6 8.26875 6H7.88438C7.65469 6 7.47656 6.19922 7.50234 6.42188C7.59375 7.16953 7.92422 7.85859 8.45391 8.4C8.73516 8.68594 8.91562 9.04453 8.97891 9.43359ZM11.6039 9.43359C11.6344 9.61406 11.7914 9.75 11.9789 9.75H12.3656C12.5953 9.75 12.7781 9.55078 12.7477 9.32812C12.6586 8.66719 12.3633 8.05781 11.8898 7.57734C11.5523 7.23281 11.3367 6.79688 11.2711 6.32344C11.2453 6.13828 11.0859 6 10.8938 6H10.5094C10.2797 6 10.0992 6.19922 10.1273 6.42188C10.2188 7.16953 10.5492 7.85859 11.0789 8.4C11.3602 8.68594 11.5406 9.04453 11.6039 9.43359ZM15.375 10.5H6.75C6.33516 10.5 6 10.8352 6 11.25V15.75C6 16.9922 7.00781 18 8.25 18H12.75C13.9922 18 15 16.9922 15 15.75H15.375C16.8234 15.75 18 14.5734 18 13.125C18 11.6766 16.8234 10.5 15.375 10.5ZM15.375 14.25H15V12H15.375C15.9961 12 16.5 12.5039 16.5 13.125C16.5 13.7461 15.9961 14.25 15.375 14.25Z', fill: 'white' }, void 0)] }), void 0) }), void 0));
};
export default Break;
