var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import useStyles from './styles';
var DropdownIcon = function (props) { return (_jsx("span", __assign({ className: useStyles().jobDoneIcon }, props, { children: _jsx("svg", __assign({ width: '24', height: '24', viewBox: '0 0 24 24', fill: 'none', xmlns: 'http://www.w3.org/2000/svg' }, { children: _jsx("path", { d: 'M12.7588 15.1142C12.3597 15.5798 11.6394 15.5798 11.2403 15.1142L7.41455 10.6508C6.85854 10.0021 7.31945 9 8.17381 9H15.8254C16.6797 9 17.1406 10.0021 16.5846 10.6508L12.7588 15.1142Z', fill: 'currentColor' }, void 0) }), void 0) }), void 0)); };
export default DropdownIcon;
