import { Auth } from 'aws-amplify';

const getAccessToken = async () => {
  try {
    const user = await Auth.currentSession();
    return user.getIdToken().getJwtToken();
  } catch (error) {
    return false;
  }
};

export default getAccessToken;
