var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { makeStyles } from '@material-ui/core';
import cx from 'classnames';
import { Icons, Button } from '../..';
import { Subtitle } from '../Typography';
var useStyles = makeStyles(function (theme) { return ({
    container: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingLeft: 2,
        paddingRight: 2,
        paddingBottom: 2,
        borderBottom: "2px solid " + theme.palette.grey[300],
    },
    titleContainer: {
        display: 'flex',
        height: 36,
        gap: 10,
    },
}); });
export var Heading = function (_a) {
    var children = _a.children, className = _a.className, icon = _a.icon, title = _a.title, buttonLabel = _a.buttonLabel, onClick = _a.onClick, rest = __rest(_a, ["children", "className", "icon", "title", "buttonLabel", "onClick"]);
    var classes = useStyles();
    return (_jsxs("div", __assign({ className: cx(className, classes.container) }, rest, { children: [_jsxs("div", __assign({ className: classes.titleContainer }, { children: [icon, _jsx(Subtitle, { children: title }, void 0)] }), void 0), buttonLabel && (_jsx(Button, { color: 'ghost', icon: _jsx(Icons.Plus, {}, void 0), label: buttonLabel, onClick: onClick, variant: 'autoWidth' }, void 0))] }), void 0));
};
