var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import cx from 'classnames';
import useStyles from './styles';
var Template = function (props) {
    var className = props.className, rest = __rest(props, ["className"]);
    return (_jsx("span", __assign({ className: cx(useStyles().jobDoneIcon, className) }, rest, { children: _jsxs("svg", __assign({ width: '24', height: '24', viewBox: '0 0 24 24', fill: 'none', xmlns: 'http://www.w3.org/2000/svg' }, { children: [_jsx("path", { d: 'M15.0965 2H8.90418C5.09723 2 2 5.09685 2 8.90346V15.0965C2 18.9031 5.09723 22 8.90418 22H15.0965C18.9031 22 22 18.9031 22 15.0965V8.90346C22 5.0969 18.9031 2 15.0965 2Z', fill: 'currentColor' }, void 0), _jsx("path", { fillRule: 'evenodd', clipRule: 'evenodd', d: 'M7.2002 7.68C7.2002 6.76821 7.9231 6 8.8502 6H13.1403C13.5834 6 14.0049 6.18123 14.3129 6.49808L16.3229 8.56543C16.6303 8.8817 16.8002 9.30716 16.8002 9.74735V16.32C16.8002 17.2318 16.0773 18 15.1502 18H8.8502C7.9231 18 7.2002 17.2318 7.2002 16.32V7.68ZM12.6002 10.2H15.6002V9.74735C15.6002 9.61468 15.5488 9.49073 15.4625 9.40192L13.4525 7.33457C13.3668 7.24634 13.2541 7.2 13.1403 7.2H12.6002V10.2Z', fill: 'white' }, void 0)] }), void 0) }), void 0));
};
export default Template;
