import { jsx as _jsx } from "react/jsx-runtime";
import { Absence, CopyIcon, Publish, Briefcase, Template, TemplatesIcon, Trash, AbsenceOutline, } from '../../icons';
export var emptyCellMenuItems = [
    {
        label: 'Create Shift',
        icon: _jsx(Briefcase, {}, void 0),
        key: 'createShiftItem',
    },
    {
        label: 'Create from template',
        icon: _jsx(Template, {}, void 0),
        key: 'createFromTemplateItem',
    },
    {
        label: 'Create absence',
        icon: _jsx(Absence, {}, void 0),
        key: 'createAbsenceItem',
    },
];
export var shiftMenuItems = [
    {
        label: 'Copy Shift',
        icon: _jsx(CopyIcon, {}, void 0),
        key: 'copyShiftItem',
    },
    {
        label: 'Save as Template',
        icon: _jsx(TemplatesIcon, {}, void 0),
        key: 'saveAsTemplateItem',
    },
    {
        label: 'Convert to Absence',
        icon: _jsx(AbsenceOutline, {}, void 0),
        key: 'convertToAbsenceItem',
    },
    {
        label: 'Delete',
        icon: _jsx(Trash, {}, void 0),
        key: 'deleteItem',
    },
    {
        label: 'Publish Shift',
        icon: _jsx(Publish, {}, void 0),
        key: 'publishItem',
    },
];
export var absenceMenuItems = [
    {
        label: 'Copy Absence',
        icon: _jsx(CopyIcon, {}, void 0),
        key: 'copyAbsenceItem',
    },
    {
        label: 'Save as Template',
        icon: _jsx(TemplatesIcon, {}, void 0),
        key: 'saveAsTemplateItem',
    },
    {
        label: 'Delete',
        icon: _jsx(Trash, {}, void 0),
        key: 'deleteItem',
    },
    {
        label: 'Publish Absence',
        icon: _jsx(Publish, {}, void 0),
        key: 'publishItem',
    },
];
