var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
var Line = function (_a) {
    var className = _a.className;
    return (_jsx("svg", __assign({ className: className, width: '8', height: '8', viewBox: '0 0 8 8', fill: 'none', xmlns: 'http://www.w3.org/2000/svg' }, { children: _jsx("path", { fillRule: 'evenodd', clipRule: 'evenodd', d: 'M7.16711 0.163881C7.37131 0.382388 7.37131 0.736659 7.16711 0.955167L0.892605 7.66945C0.688409 7.88796 0.357342 7.88796 0.153147 7.66945C-0.0510489 7.45095 -0.0510489 7.09667 0.153147 6.87817L6.42766 0.163881C6.63185 -0.0546269 6.96292 -0.0546269 7.16711 0.163881ZM7.84685 3.12817C8.05105 3.34667 8.05105 3.70095 7.84685 3.91945L4.18672 7.83612C3.98253 8.05463 3.65146 8.05463 3.44726 7.83612C3.24307 7.61761 3.24307 7.26334 3.44726 7.04483L7.10739 3.12817C7.31159 2.90966 7.64266 2.90966 7.84685 3.12817Z', fill: 'currentColor' }, void 0) }), void 0));
};
export default Line;
