var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx } from "react/jsx-runtime";
import { makeStyles } from '@material-ui/core';
import { useEffect } from 'react';
import usePlacesAutocomplete, { getGeocode, getLatLng, } from 'use-places-autocomplete';
import Autocomplete from '../Autocomplete';
var useStyles = makeStyles(function () { return ({
    search: {
        marginBottom: 8,
    },
}); });
export var AutoComplete = function (_a) {
    var getAddressDetails = _a.getAddressDetails, center = _a.center, locale = _a.locale, hiddenLabel = _a.hiddenLabel, _b = _a.defaultValue, defaultValue = _b === void 0 ? '' : _b, disabled = _a.disabled, _c = _a.debounce, debounce = _c === void 0 ? 400 : _c, hasError = _a.hasError;
    var _d = usePlacesAutocomplete({
        requestOptions: {
            location: new google.maps.LatLng(center.lat, center.lng),
            radius: 100 * 1000,
        },
        debounce: debounce,
        defaultValue: defaultValue,
    }), ready = _d.ready, value = _d.value, _e = _d.suggestions, status = _e.status, data = _e.data, setValue = _d.setValue, clearSuggestions = _d.clearSuggestions;
    var classes = useStyles();
    var findAddressDetails = function (address) { return __awaiter(void 0, void 0, void 0, function () {
        var addressComponents_1, results, _a, lat, lng, error_1;
        var _b;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    _c.trys.push([0, 3, , 4]);
                    addressComponents_1 = {
                        country: '',
                        city: '',
                        addressLine1: '',
                        zip: '',
                        longitude: 0,
                        latitude: 0,
                        state: '',
                        formattedAddress: address,
                    };
                    return [4 /*yield*/, getGeocode({ address: address })];
                case 1:
                    results = _c.sent();
                    return [4 /*yield*/, getLatLng(results[0])];
                case 2:
                    _a = _c.sent(), lat = _a.lat, lng = _a.lng;
                    addressComponents_1.latitude = lat;
                    addressComponents_1.longitude = lng;
                    (_b = results[0].address_components) === null || _b === void 0 ? void 0 : _b.forEach(function (addressComponent) {
                        if (addressComponent.types.includes('country')) {
                            addressComponents_1.country = addressComponent.short_name;
                        }
                        if (addressComponent.types.includes('locality')) {
                            addressComponents_1.city = addressComponent.long_name;
                        }
                        if (addressComponent.types.includes('administrative_area_level_1')) {
                            addressComponents_1.state = addressComponent.long_name;
                        }
                        if (addressComponent.types.includes('route')) {
                            addressComponents_1.addressLine1 = addressComponent.long_name;
                        }
                        if (addressComponent.types.includes('street_number')) {
                            addressComponents_1.addressLine1 += " " + addressComponent.long_name;
                        }
                        if (addressComponent.types.includes('postal_code')) {
                            addressComponents_1.zip = addressComponent.short_name;
                        }
                    });
                    getAddressDetails === null || getAddressDetails === void 0 ? void 0 : getAddressDetails(addressComponents_1);
                    return [3 /*break*/, 4];
                case 3:
                    error_1 = _c.sent();
                    console.log('Error: ', error_1);
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    var handleSelect = function (address) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    clearSuggestions();
                    if (!(address === null || address === void 0 ? void 0 : address.value))
                        return [2 /*return*/];
                    return [4 /*yield*/, findAddressDetails(address.value)];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); };
    useEffect(function () {
        if (!defaultValue) {
            return;
        }
        findAddressDetails(defaultValue).catch(function (e) {
            console.log(e);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [defaultValue]);
    var suggestionOptions = status === 'OK' ? data.map(function (_a) {
        var description = _a.description;
        return ({
            value: description,
            label: description,
        });
    }) : [];
    return (_jsx("div", __assign({ className: classes.search }, { children: _jsx(Autocomplete, { disabled: !ready || !!disabled, "aria-label": 'address', defaultValue: {
                value: value,
                label: value,
            }, onChange: function (e) {
                if (e.target.value === '') {
                    getAddressDetails === null || getAddressDetails === void 0 ? void 0 : getAddressDetails({
                        country: '',
                        city: '',
                        addressLine1: '',
                        zip: '',
                        longitude: 0,
                        latitude: 0,
                        state: '',
                        formattedAddress: '',
                    });
                }
                setValue(e.target.value);
            }, options: suggestionOptions, onSelectOption: handleSelect, hiddenLabel: hiddenLabel, locale: locale, error: !!hasError }, void 0) }), void 0));
};
