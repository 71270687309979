var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import cx from 'classnames';
import useStyles from './BreakEntry.styles';
import { Caption } from '../../../Typography';
import { Break } from '../../../icons';
export var BreakEntry = function (_a) {
    var _b;
    var testId = _a.testId, _c = _a.title, title = _c === void 0 ? 'Break' : _c, _d = _a.time, time = _d === void 0 ? '7:00 - 11:00' : _d, type = _a.type, duration = _a.duration, className = _a.className, selected = _a.selected, _e = _a.disabled, disabled = _e === void 0 ? false : _e, rest = __rest(_a, ["testId", "title", "time", "type", "duration", "className", "selected", "disabled"]);
    var classes = useStyles();
    return (_jsxs("div", __assign({}, rest, { "data-testid": testId, className: cx(classes.container, (_b = {},
            _b[classes.selected] = selected,
            _b[classes.disabled] = disabled,
            _b["" + String(className)] = !!className,
            _b)) }, { children: [_jsxs("div", __assign({ className: classes.topContainer }, { children: [_jsxs("div", __assign({ className: classes.iconAndDurationContainer }, { children: [_jsx(Break, { "aria-label": 'break-icon', className: classes.breakIcon }, void 0), _jsx(Caption, __assign({ className: classes.durationBlock }, { children: duration }), void 0)] }), void 0), _jsx(Caption, __assign({ variant: 'regular', className: classes.timeRange }, { children: time }), void 0)] }), void 0), _jsxs("div", __assign({ className: classes.bottomContainer }, { children: [_jsx(Caption, __assign({ variant: 'bold', className: classes.titleBlock }, { children: title }), void 0), _jsx(Caption, __assign({ variant: 'bold', className: classes.typeBlock }, { children: type }), void 0)] }), void 0)] }), void 0));
};
