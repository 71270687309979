var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { makeStyles } from '@material-ui/core';
import cx from 'classnames';
import { DropdownMenu } from '../../DropdownMenu';
import { Body } from '../../Typography';
var useStyles = makeStyles(function (theme) { return ({
    menuItem: {
        display: 'flex',
        gap: 10,
        color: theme.palette.grey[500],
        '&:hover svg': {
            color: theme.palette.grey[500],
        },
        '&:hover p': {
            color: theme.palette.primary.main,
        },
    },
    publishItem: {
        '& > *': {
            color: theme.palette.primary.main + " !important",
            fontWeight: 700,
        },
        '&:hover svg': {
            color: theme.palette.primary.main + " !important",
        },
    },
    saveAsTemplateItem: {
        borderBottom: "1px solid " + theme.palette.grey[300],
    },
    convertToAbsenceItem: {
        borderBottom: "1px solid " + theme.palette.grey[300],
    },
    deleteItem: {
        '& > *': {
            color: theme.palette.error.main + " !important",
            fontWeight: 500,
        },
        '&:hover svg': {
            color: theme.palette.error.main + " !important",
        },
        '&:hover': {
            backgroundColor: '#FFF6F5 !important',
        },
    },
    createAbsenceItem: {
        color: theme.palette.error.main,
        '&:hover svg': {
            color: theme.palette.error.main,
        },
    },
    createShiftItem: {
        color: theme.palette.primary.main,
        '&:hover svg': {
            color: theme.palette.primary.main,
        },
    },
}); });
export var EntryMenuItems = function (_a) {
    var anchorEl = _a.anchorEl, handleClose = _a.handleClose, menuItems = _a.menuItems;
    var classes = useStyles();
    return (_jsx(DropdownMenu, __assign({ "aria-label": 'entry-menu-items', anchorEl: anchorEl, open: Boolean(anchorEl), handleClose: handleClose }, { children: menuItems === null || menuItems === void 0 ? void 0 : menuItems.map(function (_a) {
            var key = _a.key, label = _a.label, icon = _a.icon, rest = __rest(_a, ["key", "label", "icon"]);
            return (_jsxs(DropdownMenu.Item, __assign({ className: cx(classes.menuItem, classes[key]) }, rest, { children: [icon || _jsx(_Fragment, {}, void 0), _jsx(Body, __assign({ variant: 'large' }, { children: label }), void 0)] }), key));
        }) }), void 0));
};
