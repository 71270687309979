var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import cx from 'classnames';
import useStyles from './styles';
var Close = function (props) {
    var className = props.className, rest = __rest(props, ["className"]);
    return (_jsx("span", __assign({ className: cx(useStyles().jobDoneIcon, className) }, rest, { children: _jsx("svg", __assign({ width: '24', height: '24', viewBox: '0 0 24 24', fill: 'none', xmlns: 'http://www.w3.org/2000/svg' }, { children: _jsx("path", { d: 'M18.3636 18.364C18.7541 17.9734 18.7541 17.3403 18.3636 16.9497L13.4145 12.0007L18.365 7.05025C18.7555 6.65973 18.7555 6.02656 18.3649 5.63604C17.9744 5.24551 17.3413 5.24551 16.9507 5.63603L12.0003 10.5865L7.04988 5.63603C6.65936 5.24551 6.02619 5.24551 5.63567 5.63603C5.24514 6.02656 5.24514 6.65972 5.63567 7.05025L10.5861 12.0007L5.63702 16.9497C5.2465 17.3403 5.2465 17.9734 5.63703 18.364C6.02755 18.7545 6.66072 18.7545 7.05124 18.364L12.0003 13.4149L16.9494 18.364C17.3399 18.7545 17.9731 18.7545 18.3636 18.364Z', fill: 'currentColor' }, void 0) }), void 0) }), void 0));
};
export default Close;
