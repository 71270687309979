var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import cx from 'classnames';
import useStyles from './styles';
var AbsenceOutline = function (props) {
    var className = props.className, rest = __rest(props, ["className"]);
    return (_jsx("span", __assign({ className: cx(useStyles().jobDoneIcon, className) }, rest, { children: _jsx("svg", __assign({ width: '24', height: '24', viewBox: '0 0 24 24', fill: 'none', xmlns: 'http://www.w3.org/2000/svg' }, { children: _jsx("path", { fillRule: 'evenodd', clipRule: 'evenodd', d: 'M15.2899 2.77892C10.9231 0.881371 5.88132 2.56453 3.53056 6.70635C3.5219 6.72159 3.51365 6.73706 3.50581 6.75274L3.42119 6.92198C2.55356 8.41683 3.1411 10.4624 4.78644 11.2103L4.80727 11.2195L9.76099 13.3365L6.84573 20H4C3.44772 20 3 20.4477 3 21C3 21.5523 3.44772 22 4 22H7.47871C7.49242 22.0003 7.50612 22.0003 7.51979 22H20C20.5523 22 21 21.5523 21 21C21 20.4477 20.5523 20 20 20H9.02876L11.6002 14.1225L16.4978 16.2155C18.14 16.9537 20.0873 16.0702 20.5599 14.2833L20.6489 14.0162C20.6552 13.9973 20.6609 13.9783 20.6661 13.9591C21.8882 9.40387 19.6745 4.58373 15.2899 2.77892ZM5.28254 7.67142L5.19467 7.84717C5.18322 7.87006 5.1709 7.8925 5.15773 7.91445C4.85803 8.41396 5.07058 9.13598 5.60558 9.38569L8.06586 10.4371C8.74565 7.87448 10.2682 5.61717 12.3997 4.04978C9.58042 3.72677 6.76377 5.08207 5.28254 7.67142ZM12.9817 12.5379L9.92836 11.233C10.518 8.70958 12.0802 6.52734 14.2901 5.18943C14.8653 7.69324 14.3719 10.3377 12.9817 12.5379ZM16.4279 5.785C16.7745 8.40178 16.1929 11.0608 14.8405 13.3322L17.2932 14.3804L17.314 14.3896C17.8571 14.6364 18.4847 14.339 18.6301 13.7574C18.6363 13.7326 18.6435 13.708 18.6516 13.6837L18.7418 13.4129C19.5018 10.5384 18.5449 7.5841 16.4279 5.785Z', fill: 'currentColor' }, void 0) }), void 0) }), void 0));
};
export default AbsenceOutline;
