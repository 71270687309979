var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { makeStyles } from '@material-ui/core';
import { buildStyles, CircularProgressbarWithChildren } from 'react-circular-progressbar';
import cx from 'classnames';
import { theme } from '../../utils';
import 'react-circular-progressbar/dist/styles.css';
import { Stop, Start } from '../icons';
import { Loading } from '../..';
var useStyles = makeStyles(function () { return ({
    smallRoot: {
        width: 56,
    },
    normalSizeRoot: {
        width: 136,
    },
    startWorkInnerCircle: {
        cursor: function (_a) {
            var isDisabled = _a.isDisabled;
            return (isDisabled ? 'default' : 'pointer');
        },
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '65%',
        height: '65%',
        borderRadius: '100%',
        border: 'none',
        backgroundColor: theme.palette.primary.main,
        '&:hover': {
            backgroundColor: theme.palette.primary.light,
        },
    },
    workIcon: {
        color: theme.palette.common.white,
    },
    startIcon: {
        transform: 'translateX(3px)',
    },
    smallIcon: {
        '& > svg': {
            padding: 10,
        },
    },
    smallStartIcon: {
        transform: 'translateX(2px)',
    },
    pulseStart: {
        border: 0,
        cursor: function (_a) {
            var isDisabled = _a.isDisabled;
            return (isDisabled ? 'default' : 'pointer');
        },
        color: theme.palette.common.white,
        fontSize: 14,
        borderRadius: '100%',
        backgroundColor: theme.palette.primary.main,
        transform: 'scale(1)',
        animation: '$pulse 2s infinite',
        boxShadow: '0 0 0 0 rgba(47, 116, 235, 1)',
    },
    '@keyframes pulse': {
        '0%': {
            transform: 'scale(0.85)',
            boxShadow: '0 0 0 0 rgba(47, 116, 235, 0.7)',
        },
        '70%': {
            transform: 'scale(1)',
            boxShadow: '0 0 0 10px rgba(47, 116, 235, 0)',
        },
        '100%': {
            transform: 'scale(0.85)',
            boxShadow: '0 0 0 0 rgba(47, 116, 235, 0)',
        },
    },
    disabled: {
        backgroundColor: 'rgba(32, 106, 233, 0.45)',
        '&:hover': {
            backgroundColor: 'rgba(32, 106, 233, 0.45)',
        },
    },
    loader: {
        width: '50%',
        height: '44%',
    },
}); });
export var WorkCircleProgressBar = function (_a) {
    var _b, _c;
    var _d = _a.hasStarted, hasStarted = _d === void 0 ? false : _d, _e = _a.handleStartStopWork, handleStartStopWork = _e === void 0 ? function () { } : _e, _f = _a.progressBarStyles, progressBarStyles = _f === void 0 ? {} : _f, _g = _a.disabled, disabled = _g === void 0 ? false : _g, _h = _a.smallIcon, smallIcon = _h === void 0 ? false : _h, _j = _a.isLoading, isLoading = _j === void 0 ? false : _j, rest = __rest(_a, ["hasStarted", "handleStartStopWork", "progressBarStyles", "disabled", "smallIcon", "isLoading"]);
    var classes = useStyles({ isDisabled: disabled || isLoading });
    var startProgressbarStyles = {
        // How long animation takes to go from one percentage to another, in seconds
        pathTransitionDuration: 0.5,
        // Colors
        pathColor: theme.palette.primary.main,
        trailColor: theme.palette.grey[300],
    };
    var renderContent = function () {
        var _a, _b;
        if (isLoading) {
            return _jsx(Loading, { color: 'circleBar', hasLabel: false, className: classes.loader }, void 0);
        }
        if (hasStarted) {
            return _jsx(Stop, { className: cx(classes.workIcon, (_a = {}, _a[classes.smallIcon] = smallIcon, _a)) }, void 0);
        }
        return (_jsx(Start, { className: cx(classes.workIcon, classes.startIcon, (_b = {},
                _b[classes.smallIcon] = smallIcon,
                _b[classes.smallStartIcon] = smallIcon,
                _b)) }, void 0));
    };
    return (_jsx("div", __assign({ className: cx((_b = {},
            _b[classes.smallRoot] = smallIcon,
            _b[classes.normalSizeRoot] = !smallIcon,
            _b)) }, { children: _jsx(CircularProgressbarWithChildren, __assign({}, rest, { strokeWidth: 12, styles: buildStyles(__assign(__assign({}, startProgressbarStyles), progressBarStyles)) }, { children: _jsx("button", __assign({ className: cx(classes.startWorkInnerCircle, (_c = {},
                    _c[classes.pulseStart] = hasStarted,
                    _c[classes.disabled] = disabled,
                    _c)), disabled: disabled, onClick: handleStartStopWork }, { children: renderContent() }), void 0) }), void 0) }), void 0));
};
