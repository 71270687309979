import { Auth } from 'aws-amplify';

export const signIn = async (email: string, password: string): Promise<any> => {
  try {
    const result = await Auth.signIn(email, password);
    return result;
  } catch (error: unknown) {
    console.log('Error signing in', error);
    return error;
  }
};

export const signOut = async () => {
  try {
    await Auth.signOut();
  } catch (error) {
    console.log('Error signing out: ', error);
  }
};

export const signOutFromAllDevices = async () => {
  try {
    await Auth.signOut({ global: true });
  } catch (error) {
    console.log('Error signing out: ', error);
  }
};
