var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import cx from 'classnames';
import useStyles from './styles/Typography.styles';
import { Text } from './Text';
var Variants = {
    large: 'large',
};
var Subtitle = function (_a) {
    var _b;
    var _c = _a.variant, variant = _c === void 0 ? Variants.large : _c, _d = _a.className, className = _d === void 0 ? '' : _d, children = _a.children, rest = __rest(_a, ["variant", "className", "children"]);
    var styles = useStyles();
    var classNames = cx(className, (_b = {},
        _b[styles.subtitleLarge] = variant === Variants.large,
        _b));
    return (_jsx(Text, __assign({}, rest, { className: classNames }, { children: children }), void 0));
};
export default Subtitle;
