import { makeStyles } from '@material-ui/core';
var useStyles = makeStyles(function (theme) { return ({
    track: {
        background: '#f5f7fa',
        height: '56px',
        width: '100%',
        position: 'relative',
    },
    progress: {
        position: 'absolute',
        background: theme.palette.primary.main,
        width: '0%',
        height: '56px',
        transition: '1s ease',
        transitionDelay: '0.5s',
    },
    content: {
        padding: '8px',
        height: '100%',
    },
}); });
export default useStyles;
