var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { makeStyles } from '@material-ui/core';
import cx from 'classnames';
import { Icons, Button } from '../..';
import { Body, Caption } from '../Typography';
var useStyles = makeStyles(function (theme) { return ({
    container: {
        padding: 16,
        backgroundColor: theme.palette.grey[100],
        borderRadius: 16,
    },
    topContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    titleContainer: {
        display: 'flex',
        gap: 10,
    },
    assignedTeamsContainer: {
        display: 'flex',
        alignItems: 'center',
        gap: 8,
        marginTop: 4,
    },
    assignedLabel: {
        color: theme.palette.grey[500],
    },
    bottomContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginTop: 16,
    },
    descriptionContainer: {
        display: 'flex',
        alignItems: 'center',
        gap: 8,
    },
    description: {
        color: theme.palette.grey[700],
        fontSize: 13,
        lineHeight: '16px',
    },
}); });
var Event = function (_a) {
    var style = _a.style, className = _a.className, icon = _a.icon, title = _a.title, locale = _a.locale, type = _a.type, time = _a.time, ends = _a.ends, onClick = _a.onClick, rest = __rest(_a, ["style", "className", "icon", "title", "locale", "type", "time", "ends", "onClick"]);
    var classes = useStyles();
    return (_jsxs("div", __assign({ style: style, className: cx(classes.container, className) }, rest, { children: [_jsxs("div", __assign({ className: classes.topContainer }, { children: [_jsx("div", { children: _jsxs("div", __assign({ className: classes.titleContainer }, { children: [icon, _jsx(Body, __assign({ variant: 'large-700' }, { children: title }), void 0)] }), void 0) }, void 0), _jsx(Button, { color: 'ghost', icon: _jsx(Icons.EditFilled, {}, void 0), onClick: onClick, size: 'small', variant: 'fixedIcon' }, void 0)] }), void 0), _jsxs("div", __assign({ className: classes.bottomContainer }, { children: [_jsxs("div", __assign({ className: classes.descriptionContainer }, { children: [_jsx(Caption, __assign({ variant: 'bold' }, { children: locale.type }), void 0), _jsx(Body, __assign({ variant: 'medium', className: classes.description }, { children: type }), void 0)] }), void 0), _jsxs("div", __assign({ className: classes.descriptionContainer }, { children: [_jsx(Caption, __assign({ variant: 'bold' }, { children: locale.time }), void 0), _jsx(Body, __assign({ variant: 'medium', className: classes.description }, { children: time }), void 0)] }), void 0), _jsxs("div", __assign({ className: classes.descriptionContainer }, { children: [_jsx(Caption, __assign({ variant: 'bold' }, { children: locale.ends }), void 0), _jsx(Body, __assign({ variant: 'medium', className: classes.description }, { children: ends }), void 0)] }), void 0)] }), void 0)] }), void 0));
};
export default Event;
