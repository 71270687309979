var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import OTP from 'react-otp-input';
import cx from 'classnames';
import useStyles from './styles/VerificationCode.styles';
var VerificationCode = function (_a) {
    var _b;
    var isSuccess = _a.isSuccess, inputStyle = _a.inputStyle, containerStyle = _a.containerStyle, className = _a.className, errorMessageStyles = _a.errorMessageStyles, errorStyle = _a.errorStyle, separator = _a.separator, numInputs = _a.numInputs, isDisabled = _a.isDisabled, hasErrored = _a.hasErrored, value = _a.value, onChange = _a.onChange, locale = _a.locale, props = __rest(_a, ["isSuccess", "inputStyle", "containerStyle", "className", "errorMessageStyles", "errorStyle", "separator", "numInputs", "isDisabled", "hasErrored", "value", "onChange", "locale"]);
    var _c = __read(useState(hasErrored), 2), isError = _c[0], setIsError = _c[1];
    useEffect(function () {
        setIsError(hasErrored);
    }, [hasErrored]);
    var handleChange = function (e) {
        onChange === null || onChange === void 0 ? void 0 : onChange(e);
        if (hasErrored)
            setIsError(false);
    };
    var styles = useStyles();
    var selectedSeparator = separator || _jsx("span", { style: { width: '8px' } }, void 0);
    var selectedNumInputs = numInputs || 6;
    var containerStyles = cx(styles.root, containerStyle);
    var inputStyles = cx(styles.input, inputStyle, (_b = {},
        _b[styles.successStyles] = isSuccess,
        _b));
    var errorStyles = cx(styles.errorStyles, errorStyle);
    return (_jsxs("div", __assign({ className: cx(styles.container, className) }, { children: [_jsx(OTP, __assign({ separator: selectedSeparator, numInputs: selectedNumInputs, containerStyle: containerStyles, inputStyle: inputStyles, errorStyle: errorStyles, isDisabled: isDisabled || isSuccess, value: value, onChange: handleChange, hasErrored: isError }, props), void 0), isError && (_jsx("span", __assign({ className: cx(styles.errorMessage, errorMessageStyles) }, { children: locale === null || locale === void 0 ? void 0 : locale.errorMessage }), void 0))] }), void 0));
};
export default VerificationCode;
