import { makeStyles } from '@material-ui/core/styles';
var useStyles = makeStyles(function (theme) { return ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
    },
    containerOnInputDisabled: {
        border: "2px solid " + theme.palette.grey[400] + " !important",
        backgroundColor: theme.palette.grey[100] + " !important",
    },
    input: {
        width: '100%',
        fontSize: 14,
        fontWeight: 500,
        minHeight: 40,
        borderRadius: 8,
        color: theme.palette.common.black,
        backgroundColor: theme.palette.grey[100],
        border: '2px solid transparent',
        transition: 'all ease-out 300ms',
        '&>input': {
            paddingLeft: 16,
            paddingRight: 10,
        },
        /* Chrome, Safari, Edge, Opera */
        '&>input::-webkit-outer-spin-button, input::-webkit-inner-spin-button': {
            '-webkit-appearance': 'none',
            margin: 0,
        },
        /* Firefox */
        '&>input[type=number]': {
            '-moz-appearance': 'textfield',
        },
        '&>input::placeholder': {
            color: theme.palette.grey[500],
            opacity: 1,
            verticalAlign: 'middle',
        },
        '&:hover': {
            border: "2px solid " + theme.palette.grey[400],
            backgroundColor: theme.palette.common.white,
        },
        '&:hover + $line': {
            color: theme.palette.grey[400],
        },
        '&:focus-within': {
            color: theme.palette.common.black,
            border: "2px solid " + theme.palette.primary.main,
            backgroundColor: theme.palette.common.white,
        },
        '&:focus-within ~ $line': {
            color: theme.palette.primary.main,
        },
        '&:focus-within $eyeIcon>svg': {
            color: theme.palette.primary.main,
        },
    },
    label: {
        color: theme.palette.common.black,
        fontWeight: 700,
        marginBottom: '0.8rem',
        fontSize: 13,
        textAlign: 'left',
        display: 'flex',
        alignItems: 'flex-start',
    },
    labelActive: {
        color: theme.palette.primary.main,
    },
    eyeIcon: {
        display: 'flex',
        alignSelf: 'center',
        '& > svg': {
            userSelect: 'none',
            transition: 'all ease-out 300ms',
            color: theme.palette.grey[500],
        },
        '&:hover > svg': {
            color: theme.palette.primary.main,
        },
        marginRight: 21,
        cursor: 'pointer',
    },
    /* Error case start */
    inputError: {
        border: "2px solid " + theme.palette.error.main + " !important",
    },
    labelError: {
        color: theme.palette.error.main + " !important",
    },
    errorMessage: {
        fontSize: 13,
        fontWeight: 500,
        marginTop: 6,
        color: theme.palette.error.main,
        textAlign: 'left',
    },
    errorTypePassowrdContainer: {
        display: 'flex',
        height: 36,
    },
    errorIcon: {
        display: 'flex',
        alignSelf: 'center',
        alignItems: 'center',
        padding: '0 21px',
        borderLeft: "2px solid " + theme.palette.error.main,
        height: '100%',
    },
    /* Error case end */
    /* Success case start */
    inputSuccess: {
        border: "2px solid " + theme.palette.success.main,
    },
    labelSuccess: {
        color: theme.palette.success.main,
    },
    /* Success case end */
    inputContainer: {
        position: 'relative',
        width: '100%',
    },
    line: {
        position: 'absolute',
        right: 5,
        bottom: 5,
        color: theme.palette.grey[400],
    },
    lineError: {
        color: theme.palette.error.main + " !important",
    },
    lineSuccess: {
        color: theme.palette.success.main,
    },
    skeletonInputContainer: {
        width: '100%',
        height: '40px',
        borderRadius: 8,
    },
    dot: {
        fontSize: 22,
        lineHeight: 0,
        color: theme.palette.error.main,
    },
    buttonAsDiv: {
        backgroundColor: 'unset',
        border: 'none',
    },
    note: {
        fontFamily: theme.typography.fontFamily,
        display: 'block',
        marginBottom: 8,
        marginTop: -6,
        fontSize: 13,
        lineHeight: '16px',
        color: theme.palette.grey[700],
        '&>span': {
            fontWeight: 700,
            color: theme.palette.common.black,
        },
    },
}); });
export default useStyles;
