var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import cx from 'classnames';
import useStyles from './styles';
var Absence = function (props) {
    var _a;
    var className = props.className, rest = __rest(props, ["className"]);
    return (_jsx("span", __assign({ className: cx(useStyles().jobDoneIcon, (_a = {},
            _a[String(className)] = className,
            _a)) }, rest, { children: _jsxs("svg", __assign({ width: '24', height: '24', viewBox: '0 0 24 24', fill: 'none', xmlns: 'http://www.w3.org/2000/svg' }, { children: [_jsx("path", { d: 'M15.0965 2H8.90418C5.09723 2 2 5.09685 2 8.90346V15.0965C2 18.9031 5.09723 22 8.90418 22H15.0965C18.9031 22 22 18.9031 22 15.0965V8.90346C22 5.0969 18.9031 2 15.0965 2Z', fill: 'currentColor' }, void 0), _jsx("g", __assign({ clipPath: 'url(#clip0_1478:463)' }, { children: _jsx("path", { d: 'M14.8884 6.75C14.0709 6.75 13.3495 7.07391 12.8657 7.57219C12.527 6.66305 11.5413 6 10.3635 6C9.04002 6 7.94572 6.83039 7.75517 7.91344C7.74838 7.95532 7.75086 7.99818 7.76242 8.039C7.77399 8.07982 7.79437 8.1176 7.82213 8.14969C7.84988 8.18178 7.88434 8.2074 7.92306 8.22473C7.96179 8.24206 8.00384 8.25068 8.04627 8.25H8.87595L9.25095 7.5L9.62595 8.25H10.3331C9.53127 9.07031 9.40447 10.2863 9.99228 11.123C10.0945 11.2683 10.3003 11.2943 10.4259 11.1687L12.0899 9.50461C12.1837 11.3707 11.8321 13.2457 11.5977 14.2507H10.3478C9.53896 14.2506 8.75695 14.541 8.14421 15.0691C7.53147 15.5971 7.12876 16.3276 7.00939 17.1277C6.94166 17.5852 7.29134 18 7.75377 18H15.251C15.6836 18 16.0504 17.6311 15.9963 17.202C15.8322 15.897 14.8933 14.8348 13.676 14.4333C13.915 12.3546 13.5044 10.1283 13.2419 9H15.626L16.001 8.25L16.376 9H17.2056C17.2481 9.00068 17.2901 8.99206 17.3288 8.97473C17.3676 8.9574 17.402 8.93178 17.4298 8.89969C17.4575 8.8676 17.4779 8.82982 17.4895 8.789C17.501 8.74818 17.5035 8.70532 17.4967 8.66344C17.3062 7.58039 16.2119 6.75 14.8884 6.75Z', fill: 'white' }, void 0) }), void 0), _jsx("defs", { children: _jsx("clipPath", __assign({ id: 'clip0_1478:463' }, { children: _jsx("rect", { width: '10.5', height: '12', fill: 'white', transform: 'translate(7 6)' }, void 0) }), void 0) }, void 0)] }), void 0) }), void 0));
};
export default Absence;
