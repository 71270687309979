import { makeStyles } from '@material-ui/core/styles';
var useStyles = makeStyles(function () { return ({
    jobDoneIcon: {
        display: 'inline-block',
        color: 'inherit',
        fontStyle: 'normal',
        lineHeight: 0,
        textAlign: 'center',
        textTransform: 'none',
        '& svg': {
            display: 'inline-block',
        },
        '& > *': {
            lineHeight: 1,
        },
    },
}); });
export default useStyles;
