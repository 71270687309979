var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { usePagination } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core/styles';
import cx from 'classnames';
import { Icons } from '../../index';
var useStyles = makeStyles(function (theme) { return ({
    root: {
        display: 'flex',
        flexDirection: 'row',
        listStyleType: 'none',
        margin: 0,
        padding: 0,
        gap: 4,
        alignItems: 'center',
    },
    item: {
        userSelect: 'none',
        display: 'flex',
        transition: theme.transitions.create('all'),
        color: theme.palette.common.black,
        '& > button': {
            fontFamily: theme.typography.fontFamily,
            fontSize: 15,
            color: theme.palette.common.black,
            fontWeight: 500,
            transition: theme.transitions.create('all'),
            outline: 'none',
            backgroundColor: 'unset',
            minWidth: 40,
            height: 40,
            borderRadius: 8,
            cursor: 'pointer',
            '&:hover:enabled': {
                backgroundColor: theme.palette.secondary.light,
                color: theme.palette.primary.light,
            },
            '&:disabled': {
                color: theme.palette.grey[500] + " !important",
                cursor: 'not-allowed',
            },
            border: 'none',
        },
    },
    selected: {
        fontWeight: 500,
        color: theme.palette.common.white + " !important",
        backgroundColor: theme.palette.primary.main + " !important",
        '&:hover': {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.common.white + " !important",
        },
    },
    navBtn: {
        display: 'flex',
        alignItems: 'center',
        color: theme.palette.primary.main + " !important",
        verticalAlign: 'middle',
        '& > svg': {
            verticalAlign: 'middle',
        },
    },
    navBtnDisabled: {
        display: 'none',
    },
    navBtnText: {
        fontWeight: 700,
    },
    navBtnPrev: {
        marginRight: 40,
    },
    navBtnNext: {
        marginLeft: 40,
    },
}); });
export var Pagination = function (_a) {
    var _b = _a.locale, locale = _b === void 0 ? { next: 'Next', prev: 'Back' } : _b, _c = _a.totalCount, totalCount = _c === void 0 ? 10 : _c, _d = _a.value, value = _d === void 0 ? 1 : _d, _e = _a.setValue, setValue = _e === void 0 ? function () { } : _e, className = _a.className, isMini = _a.isMini, disabled = _a.disabled, rest = __rest(_a, ["locale", "totalCount", "value", "setValue", "className", "isMini", "disabled"]);
    var classes = useStyles();
    var items = usePagination({
        count: totalCount,
        onChange: function (_, p) { return setValue(p); },
        page: value,
    }).items;
    if (isMini) {
        return (_jsx("ul", __assign({ className: cx(classes.root, className) }, rest, { children: items.map(function (_a, index) {
                var page = _a.page, type = _a.type, selected = _a.selected, item = __rest(_a, ["page", "type", "selected"]);
                var children = null;
                if (type === 'page' && selected) {
                    children = (_jsx("button", __assign({ type: 'button', className: classes.selected }, item, { disabled: item.disabled || disabled }, { children: page }), void 0));
                }
                else if (type === 'previous') {
                    children = (_jsx("button", __assign({ type: 'button' }, item, { disabled: item.disabled || disabled, className: cx(classes.navBtn, classes.navBtnPrev) }, { children: _jsx(Icons.ShevronLeft, {}, void 0) }), void 0));
                }
                else if (type === 'next') {
                    children = (_jsx("button", __assign({ type: 'button' }, item, { disabled: item.disabled || disabled, className: cx(classes.navBtn, classes.navBtnNext) }, { children: _jsx(Icons.ShevronRight, {}, void 0) }), void 0));
                }
                // eslint-disable-next-line react/no-array-index-key
                return _jsx("li", __assign({ className: classes.item }, { children: children }), index);
            }) }), void 0));
    }
    return (_jsx("ul", __assign({ className: cx(classes.root, className) }, rest, { children: items.map(function (_a, index) {
            var _b;
            var page = _a.page, type = _a.type, selected = _a.selected, item = __rest(_a, ["page", "type", "selected"]);
            var children = null;
            if (type === 'start-ellipsis' || type === 'end-ellipsis') {
                children = '…';
            }
            else if (type === 'page') {
                children = (_jsx("button", __assign({ type: 'button', className: cx((_b = {}, _b[classes.selected] = selected, _b)) }, item, { disabled: item.disabled || disabled }, { children: page }), void 0));
            }
            else if (type === 'previous') {
                children = (_jsxs("button", __assign({ type: 'button' }, item, { disabled: item.disabled || disabled, className: cx(classes.navBtn, classes.navBtnPrev) }, { children: [_jsx(Icons.ShevronLeft, {}, void 0), _jsx("span", __assign({ className: classes.navBtnText }, { children: locale.prev }), void 0)] }), void 0));
            }
            else if (type === 'next') {
                children = (_jsxs("button", __assign({ type: 'button' }, item, { disabled: item.disabled || disabled, className: cx(classes.navBtn, classes.navBtnNext) }, { children: [_jsx("span", __assign({ className: classes.navBtnText }, { children: locale.next }), void 0), _jsx(Icons.ShevronRight, {}, void 0)] }), void 0));
            }
            // eslint-disable-next-line react/no-array-index-key
            return _jsx("li", __assign({ className: classes.item }, { children: children }), index);
        }) }), void 0));
};
