var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useState } from 'react';
import GrayEye from '../icons/GrayEye';
import Input from './Input';
import useStyles from './styles/Input.styles';
var Password = function (_a) {
    var disabled = _a.disabled, props = __rest(_a, ["disabled"]);
    var styles = useStyles();
    var _b = __read(useState(false), 2), visible = _b[0], setVisible = _b[1];
    return (_jsx(Input, __assign({}, props, { type: visible ? 'text' : 'password', passwordField: true, testId: 'ui-lib-input-password', disabled: disabled, endAdornment: (_jsx(GrayEye, { className: styles.eyeIcon, "data-testid": 'input-password-eye-icon', onClick: function () { return !disabled && setVisible(!visible); } }, void 0)) }), void 0));
};
export default Password;
